import Input from '../Input'

const EmailInput = ({ errors, register }) => {
  return (
    <Input
      type="email"
      label="Email"
      placeholder="Enter your email"
      required
      help={errors?.email?.message}
      {...register('email', {
        required: 'This field should not be empty',
        pattern: {
          value: /^[(\w\d\W)+]+@[?:.(\w\d\W)+]+$/i,
          message: 'This email is invalid',
        },
      })}
    />
  )
}

export default EmailInput
