import { Forgot, PasswordContainer } from '../../pages/SignInUp/SignInUp.styled'
import Input from '../Input'

const PasswordInputs = ({
  errors,
  register,
  condition,
  watch,
  variant,
  setError,
  clearError,
}) => {
  const pswPattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/g
  const pswExplanation =
    'Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters'

  return (
    <>
      <PasswordContainer variant={variant}>
        <Input
          variant={variant}
          type="password"
          label="password"
          placeholder={
            variant === 'change_password'
              ? 'Enter new password'
              : 'Enter your password'
          }
          required
          help={errors.password?.message}
          status={errors.password?.message}
          title={condition ? pswExplanation : 'Please enter your password'}
          {...register('password', {
            required: 'This field should not be empty',
            ...(condition && {
              pattern: {
                value: pswPattern,
                message:
                  'must contain at least one uppercase , number and lowercase character',
              },
              minLength: {
                value: 8,
                message: 'The password should be at least 8 characters',
              },
              validate: (val) => {
                if (watch('oldPassword') === val) {
                  return 'please use a different password'
                }
                if (watch('repeat') !== val) {
                  setError('repeat', {
                    type: 'validate',
                    message: 'Your passwords do no match',
                  })
                } else {
                  clearError('repeat')
                }
              },
            }),
          })}
        />
        {!condition && <Forgot to="/recover-by-email">Forgot password?</Forgot>}
      </PasswordContainer>

      {condition && (
        <div
          style={{
            marginTop: '10px',
            width: variant === 'change_password' ? '100%' : 'unset',
          }}
        >
          <Input
            variant={variant}
            type="password"
            label="Repeat password"
            placeholder={
              variant === 'change_password'
                ? 'Repeat new password'
                : 'Repeat your password'
            }
            required
            {...register('repeat', {
              required: 'This field should not be empty',
              minLength: {
                value: 8,
                message: 'The password should be at least 8 characters',
              },
              validate: (val) => {
                if (watch('password') !== val) {
                  return 'Your passwords do no match'
                }
              },
            })}
            help={errors.repeat?.message}
            status={errors.repeat?.message}
            title={pswExplanation}
          />
        </div>
      )}
    </>
  )
}

export default PasswordInputs
