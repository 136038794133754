import styled, { css } from 'styled-components'
import Button from '../Button'

export const StyledButton = styled(Button)`
  padding: 24px;
  width: 100%;
  font-size: 16px;

  // styles from ActionDetailsButton (Card.styled.js)
  ${({ location, variant }) => {
    if (location === 'main page') {
      if (variant === 'carousel') {
        return css`
          border: none;
          font-size: 12px;
          font-weight: 500;
          line-height: 100%;

          width: 205px;
          height: 44px;
          padding: 16px 0;

          @media (max-width: 985px) {
            width: 100%;
          }
        `
      }
    }
  }}
`
