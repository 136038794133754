import React, { useEffect, useRef, useState } from 'react'
import { Tab, Tabs, TabPanel, TabsList } from './Tabs.styled'
import ProjectDetails from '../ProjectDetails'
import TabMetrics from '../TabMetrics'
import ProjectTeam from '../ProjectTeam'
import ProjectPartners from '../ProjectPartners'
import { useNavigate, useParams } from 'react-router-dom'
import ProjectAbout from '../ProjectAbout'

function ProjectTabs({
  name,
  startDate,
  endDate,
  about,
  team,
  partners,
  campaign,
  tokenDistribution,
  tokenReleaseSchedule,
  salesRoundDetails,
  ath,
  roi,
}) {
  const [tabIndex, setTabIndex] = useState(0)
  const ref = useRef()
  const navigate = useNavigate()
  const { projectName, tab } = useParams()
  const tabs = {
    0: 'project-details',
    1: 'team-and-partners',
    2: 'metrics',
    3: 'campaign-details',
  }

  useEffect(() => {
    const tabValue = Object.entries(tabs).filter(([_, value]) => value === tab)
    if (tabValue.length > 0) {
      const [[tabKey]] = tabValue
      setTabIndex(+tabKey)
    }
  }, [tab]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabChange = (index) => {
    setTabIndex(index)
    index === 0
      ? ref.current?.scrollTo({ left: 0, behavior: 'smooth' })
      : index === 1
      ? ref.current?.scrollTo({ left: 130, behavior: 'smooth' })
      : index === 2
      ? ref.current?.scrollTo({
          left: 350,
          behavior: 'smooth',
        })
      : ref.current?.scrollTo({
          left: 1000,
          behavior: 'smooth',
        })
    navigate(`/projects/${projectName}/${tabs[index]}`)
  }

  return (
    <Tabs
      value={tabIndex}
      onChange={(e, index) => handleTabChange(index)}
      variant="scrollable"
    >
      <TabsList ref={ref}>
        <Tab>Details</Tab>
        <Tab>Team & partners</Tab>
        <Tab>Metrics</Tab>
        <Tab>campaign details</Tab>
      </TabsList>
      <TabPanel value={0}>
        <ProjectDetails startDate={startDate} endDate={endDate} about={about} />
      </TabPanel>
      <TabPanel value={1}>
        <>
          <ProjectTeam name={name} team={team} />
          <ProjectPartners name={name} partners={partners} />
        </>
      </TabPanel>
      <TabPanel value={2}>
        <TabMetrics
          tokenDistribution={tokenDistribution}
          tokenReleaseSchedule={tokenReleaseSchedule}
          salesRoundDetails={salesRoundDetails}
          ath={ath}
          roi={roi}
        />
      </TabPanel>
      <TabPanel value={3}>
        <ProjectAbout about={campaign} />
      </TabPanel>
    </Tabs>
  )
}

export default ProjectTabs
