import styled from '@emotion/styled'

export const HelpPageContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 48px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`

export const HelpPageParagraph = styled.p`
  text-transform: uppercase;
  margin-top: 15px;
  font-weight: 500;

  a {
    color: blue;
    font-weight: 800;
  }
`
export const HelpPageTitle = styled.h3`
  text-transform: uppercase;
  word-break: break-word;
  margin-top: 20px;
`
export const HelpPageMainTitle = styled.h1`
  text-transform: uppercase;
  word-break: break-word;
  font-size: 38px;
`
export const Ul = styled.ul`
  padding: 10px 0 0 20px;
  font-weight: 500;
  text-transform: uppercase;
  a {
    color: blue;
    font-weight: 800;
  }
`
