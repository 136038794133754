import { useContext, useEffect } from 'react'
import { UserAuthContext } from '../contexts/UserAuthContext'
import { useForm } from 'react-hook-form'

export const useSignForm = (signUp) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      ...(signUp && { repeat: '' }),
    },
    mode: 'onChange',
  })

  const { login, regUser, errorText, resendActivation } =
    useContext(UserAuthContext)

  const submitForm = (form) => {
    if (errorText === 'Something went wrong, please try again later') {
      resendActivation(form)
    } else if (!signUp) {
      login(form)
    } else {
      regUser(form)
    }
  }

  useEffect(() => {
    reset()
  }, [signUp]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    isValid,
    errors,
    isDirty,
    submitForm,
    handleSubmit,
    watch,
    register,
  }
}
