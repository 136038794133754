import Emoji from '../components/Emoji'

export const AlertBannerInfo = (name, minStFitfi = '') => {
  switch (name) {
    case 'kycNotSubmitted':
      return {
        title: 'KYC',
        description:
          'Submit your documents to increase account functionalities.',
        actionButtonText: 'Submit',
      }
    case 'kycPending':
      return {
        title: 'KYC',
        description:
          'Your KYC documents submitted. Verifications will take a few hours or longer if info or documents are unclear. Results and updates will be emailed to your email address.',
      }
    case 'kycRejected':
      return {
        title: 'KYC',
        description:
          'Your documents were rejected or additional information required. Please check your email for details.',
      }
    case 'address':
      return {
        title: 'Wallet not connected',
        description:
          'Please link your wallet address to your account. Later you can change it through support.',
        actionButtonText: 'Set',
      }
    case 'wrongAddress':
      return {
        title: 'Wrong address connected',
        description:
          'Please connect to the correct eth address linked to your account or change it through support.',
      }
    case 'zeroBalance':
      return {
        title: 'Low balance',
        description: `You need to have at least ${minStFitfi} stFITFI to take part in sales.`,
        actionButtonText: 'Stake',
      }
    default:
      return { title: '', description: '' }
  }
}

export const LaunchpadBannerInfo = {
  title: 'Step launchpad',
  description:
    'Your entry into the step ecosystem. Catch the projects before they become public.',
  actionButtonText: 'Project? Apply for listing',
}

export const StakeBannerInfo = {
  title: 'Stake your assets',
  description: 'earn yield with staking up token get autocompounding rewards.',
  actionButtonText: 'Staking app',
}

export const EmailConfirmedNotification =
  "We've sent you a confirmation email. Please check your mailbox."

export const PasswordRecoveryNotification =
  "We've sent you an email with password reset link. Please check your mailbox."

export const AdddresLinkedNotification =
  'Your address has been successfully linked.'

export const UniqueAdddresErrorNotification =
  'Your eth address is linked to another account. Please use new one.'

export const ETH_LINKING_STATUS = {
  success: 'success',
  uniqueError: 'uniqueError',
  undefined: 'undefined',
}

export const PROJECT_STATUS = {
  pending: 'pending',
  upcoming: 'upcoming',
  apply: 'registration', // todo: change to apply when back is ready
  lottery: 'lottery',
  sale: 'on sale', // todo: change to sale when back is ready
  distributed: 'distributed',
  vesting: 'vesting',
}

export const USER_STATUS = {
  authorized: 'authorized',
  unauthorized: 'unauthorized',
  applied: 'applied',
  allocated: 'allocated',
}

export const ACTION_BUTTON_TEXT = {
  [USER_STATUS.unauthorized]: {
    [PROJECT_STATUS.pending]: null,
    [PROJECT_STATUS.upcoming]: 'Sign in to apply',
    [PROJECT_STATUS.apply]: 'Sign in to apply',
    [PROJECT_STATUS.lottery]: 'Sign in for details',
    [PROJECT_STATUS.sale]: 'Sign in to buy',
    [PROJECT_STATUS.distributed]: 'Sign in for details',
    [PROJECT_STATUS.vesting]: 'Claim',
  },
  [USER_STATUS.authorized]: {
    [PROJECT_STATUS.pending]: null,
    [PROJECT_STATUS.upcoming]: 'Apply (soon)',
    [PROJECT_STATUS.apply]: 'Apply',
    [PROJECT_STATUS.lottery]: null,
    [PROJECT_STATUS.sale]: null,
    [PROJECT_STATUS.distributed]: 'Closed',
    [PROJECT_STATUS.vesting]: 'Claim',
  },
  [USER_STATUS.applied]: {
    [PROJECT_STATUS.pending]: null,
    [PROJECT_STATUS.upcoming]: 'Apply (soon)', // на всякий случай, если с бэка придёт противоречивая информация
    [PROJECT_STATUS.apply]: 'Already applied',
    [PROJECT_STATUS.lottery]: 'Applied',
    [PROJECT_STATUS.sale]: 'Buy',
    [PROJECT_STATUS.distributed]: 'Closed',
    [PROJECT_STATUS.vesting]: 'Claim',
  },
  [USER_STATUS.allocated]: {
    [PROJECT_STATUS.pending]: null,
    [PROJECT_STATUS.upcoming]: null,
    [PROJECT_STATUS.apply]: null,
    [PROJECT_STATUS.lottery]: null,
    [PROJECT_STATUS.sale]: 'Buy',
    [PROJECT_STATUS.distributed]: 'Closed',
    [PROJECT_STATUS.vesting]: 'Claim',
  },
}

export const ACTION_HELPER_TEXT = {
  [USER_STATUS.unauthorized]: {
    [PROJECT_STATUS.pending]: null,
    [PROJECT_STATUS.upcoming]: null,
    [PROJECT_STATUS.apply]: null,
    [PROJECT_STATUS.lottery]: 'Lottery in progress...',
    [PROJECT_STATUS.sale]: null,
    [PROJECT_STATUS.distributed]: null,
  },
  [USER_STATUS.authorized]: {
    [PROJECT_STATUS.pending]: null,
    [PROJECT_STATUS.upcoming]: null,
    [PROJECT_STATUS.apply]: null,
    [PROJECT_STATUS.lottery]: (
      <span>
        You didn’t apply on previous stage{' '}
        <Emoji label="crying face" symbol="😢" />. Stay tuned for upcoming
        projects
      </span>
    ),
    [PROJECT_STATUS.sale]:
      'You didn’t apply on previous stage or weren’t allocated. Stay tuned for upcoming projects',
    [PROJECT_STATUS.distributed]: null,
  },
  [USER_STATUS.applied]: {
    [PROJECT_STATUS.pending]: null,
    [PROJECT_STATUS.upcoming]: null,
    [PROJECT_STATUS.apply]: null,
    [PROJECT_STATUS.lottery]: 'WAITING LOTTERY OUTCOME Stay tuned',
    [PROJECT_STATUS.sale]:
      'You didn’t apply on previous stage or weren’t allocated. Stay tuned for upcoming projects',
    [PROJECT_STATUS.distributed]: null,
  },
  [USER_STATUS.allocated]: {
    [PROJECT_STATUS.pending]: null,
    [PROJECT_STATUS.upcoming]: null,
    [PROJECT_STATUS.apply]: null,
    [PROJECT_STATUS.lottery]: null,
    [PROJECT_STATUS.sale]: null,
    [PROJECT_STATUS.distributed]: null,
  },
}

export const KYC_STATUS = {
  notSubmitted: 'NOT_SUBMITTED',
  pending: 'PENDING',
  rejected: 'REJECTED',
  approved: 'APPROVED',
}
