import styled from 'styled-components'
import CloseIcon from '@mui/icons-material/Close'

export const NotificationPopUpContainer = styled.div`
  position: fixed;
  display: none;
  top: ${({ variant }) => {
    switch (variant) {
      case 'bottom':
        return 'unset'
      default:
        return '104px !important'
    }
  }};
  right: 48px !important;
  bottom: ${({ variant }) => {
    switch (variant) {
      case 'bottom':
        return '30px'
      default:
        return 'unset'
    }
  }};
  padding: 24px;
  background: ${({ isError }) => {
    if (isError) {
      return 'rgba(238,82,83,0.2)'
    } else {
      return 'rgba(208, 208, 208, 0.2)'
    }
  }};
  box-shadow: none;
  width: 447px;
  min-height: 108px;
  z-index: 2;
  border-radius: 24px;
  backdrop-filter: blur(28.5px);
  animation: growDown 350ms;

  @media (max-width: 768px) {
    top: 75px !important;
    right: 0 !important;
    z-index: 2;
    width: 100%;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    bottom: unset;
  }

  @media (max-width: 400px) {
    height: unset;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
  }
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #000000;
  }
  p {
    margin-top: 13px;
    max-width: 280px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #000000;
    opacity: 0.5;
  }

  @keyframes growDown {
    0% {
      margin-top: -100px;
    }
    100% {
      margin-top: 0px;
    }
  }
`

export const IconClose = styled(CloseIcon)`
  position: absolute;
  fill: black;
  font-size: 28px !important;
  cursor: pointer;
  top: 16px;
  right: 24px;
`

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: ${({ isError }) => {
    if (isError) {
      return 'rgba(238,82,83,1)'
    } else {
      return '#f9d549'
    }
  }};
`
