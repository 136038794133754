import React, { useContext, useEffect } from 'react'
import Button from '../../components/Button'
import Input from '../../components/Input'
import { useNavigate, Link, useSearchParams } from 'react-router-dom'
import { useSignForm } from '../../hooks/useSignForm'
import {
  PageContainer,
  Header,
  ButtonsContainer,
  SignForm,
  OtherAction,
  PasswordContainer,
  Forgot,
  StyledHelp,
} from './SignInUp.styled'
import { UserAuthContext } from '../../contexts/UserAuthContext'
import EmailInput from '../../components/EmailInput'
import NotificationPopUp from '../../components/NotificationPopUp'
import { EmailConfirmedNotification } from '../../constants'

function SignInUp({ signUp }) {
  const {
    watch,
    handleSubmit,
    submitForm,
    register,
    errors,
    isDirty,
    isValid,
  } = useSignForm(signUp)

  const { errorText, setErrorText } = useContext(UserAuthContext)

  const pswPattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/g
  const pswExplanation =
    'Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters'
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const returnTo = searchParams.get('return_to')
  const goToPrevious = returnTo === '/help' || returnTo?.includes('/projects/')

  useEffect(() => {
    setErrorText('')

    return () => setErrorText('')
  }, [signUp]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageContainer>
      <SignForm id="sign" noValidate onSubmit={handleSubmit(submitForm)}>
        <Header>{signUp ? 'Sign Up' : 'Sign In'}</Header>
        <EmailInput errors={errors} register={register} />
        <PasswordContainer>
          <Input
            type="password"
            label="password"
            placeholder="Enter your password"
            required
            help={errors.password?.message}
            status={errors.password?.message}
            title={signUp ? pswExplanation : 'Please enter your password'}
            {...register('password', {
              required: 'This field should not be empty',
              ...(signUp && {
                pattern: {
                  value: pswPattern,
                  message:
                    'must contain at least one uppercase , number and lowercase character',
                },
                minLength: {
                  value: 8,
                  message: 'The password should be at least 8 characters',
                },
              }),
            })}
          />
          {!signUp && (
            <Forgot
              to={`/recovery-email${returnTo ? `?return_to=${returnTo}` : ''}`}
            >
              Forgot password?
            </Forgot>
          )}
        </PasswordContainer>

        {signUp && (
          <Input
            type="password"
            label="Repeat password"
            placeholder="Repeat your password"
            required
            {...register('repeat', {
              required: 'This field should not be empty',
              minLength: {
                value: 8,
                message: 'The password should be at least 8 characters',
              },
              validate: (val) => {
                if (watch('password') !== val) {
                  return 'Your passwords do no match'
                }
              },
            })}
            help={errors.repeat?.message}
            status={errors.repeat?.message}
            title={pswExplanation}
          />
        )}

        <ButtonsContainer>
          <Button disabled={!isDirty || !isValid} type="submit" form="sign">
            {errorText === 'Something went wrong, please try again later'
              ? 'resend'
              : signUp
              ? 'Sign Up'
              : 'Sign In'}
          </Button>
          <Button
            variant="transparent"
            type="reset"
            onClick={() =>
              returnTo && goToPrevious ? navigate(returnTo) : navigate('/')
            }
          >
            Cancel
          </Button>
        </ButtonsContainer>
        {errorText && (
          <StyledHelp style={{ position: 'unset' }}>{errorText}</StyledHelp>
        )}
        {errorText && errorText.includes('already exists') && (
          <OtherAction style={{ marginTop: '0' }}>
            Forgot your password?{' '}
            <Link
              to={`/recovery-email${returnTo ? `?return_to=${returnTo}` : ''}`}
            >
              Reset
            </Link>{' '}
          </OtherAction>
        )}
        {signUp ? (
          <OtherAction style={errorText ? { marginTop: '0' } : null}>
            Already registered?{' '}
            <Link to={`/signin${returnTo ? `?return_to=${returnTo}` : ''}`}>
              Sign In
            </Link>
          </OtherAction>
        ) : (
          <OtherAction>
            Missing account?{' '}
            <Link to={`/signup${returnTo ? `?return_to=${returnTo}` : ''}`}>
              Sign Up
            </Link>
          </OtherAction>
        )}
      </SignForm>
      <NotificationPopUp
        message={EmailConfirmedNotification}
        variant="registration"
      />
    </PageContainer>
  )
}

export default SignInUp
