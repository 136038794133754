import fetch from './fetch'

export async function getAllProjects() {
  return await fetch(`/projects/`, { method: 'GET', withSoftAuth: true })
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .then((response) => response.json())
    .catch((err) => {
      throw new Error(err)
    })
}

export async function getProjectByName(name) {
  return await fetch(`/projects/${name}/`, {
    method: 'GET',
    withSoftAuth: true,
  })
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .then((response) => response.json())
    .catch((err) => {
      throw new Error(err)
    })
}

export async function postApplicationForm(amount, name) {
  return await fetch(`/projects/${name}/apply/`, {
    method: 'POST',
    withAuth: true,
    body: JSON.stringify({
      amount,
    }),
  })
    .then(async (response) => {
      const json = await response.json()

      if (response.ok) {
        return json
      }

      const error = json.non_field_errors || response.status
      throw new Error(error)
    })
    .catch((err) => {
      console.log(err.message)
      throw new Error(err)
    })
}
