import styled from 'styled-components'

export const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
`

export const Th = styled.th`
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
  opacity: 0.5;
  width: 295px;
  font-weight: 500;

  @media (max-width: 768px) {
    width: 160px;
  }
`

export const Tr = styled.tr`
  display: block;
  padding-bottom: 16px;

  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  :last-child {
    padding-bottom: 0;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 12px;
    padding-bottom: 12px;
  }
`
