import { useEffect, useState } from 'react'
import { getConfig } from '../api'

const useConfig = () => {
  const [config, setConfig] = useState({})

  const getUserData = async () => {
    const config = await getConfig()
    setConfig(config)
  }

  useEffect(() => {
    getUserData()
  }, [])

  return {
    config,
  }
}

export default useConfig
