import styled from 'styled-components'

export const SocialLinkContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  a {
    height: 32px;
  }
  @media (max-width: 1174px) {
    display: ${({ variant }) => {
      if (variant === 'projectPage') {
        return 'flex'
      } else {
        return 'grid'
      }
    }};
    grid-template-columns: ${({ variant }) => {
      if (variant === 'projectPage') {
        return 'unset'
      } else {
        return '1fr 1fr 1fr'
      }
    }};
    flex-wrap: ${({ variant }) => {
      if (variant === 'projectPage') {
        return 'wrap'
      } else {
        return 'unset'
      }
    }};
  }
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`

export const SocialLink = styled.a`
  
`
