import React, { useContext } from 'react'
import { UserAuthContext, ConfigContext } from '../../contexts'
import {
  ACTION_HELPER_TEXT,
  PROJECT_STATUS,
  USER_STATUS,
} from '../../constants'
import { Helper, Link } from './ActionHelper.styled'
import { getUserStatus } from '../../utils/getUserStatus'

function ActionHelper({
  status,
  applied,
  amount,
  location,
  column,
  allocation,
}) {
  const { isLogined } = useContext(UserAuthContext)
  const { config } = useContext(ConfigContext)

  const userStatus = getUserStatus({ isLogined, applied, allocation })
  const text = ACTION_HELPER_TEXT[userStatus][status]

  const needAllocation =
    status === PROJECT_STATUS.distributed && userStatus === USER_STATUS.applied

  const needDiscord =
    (status === PROJECT_STATUS.lottery &&
      (userStatus === USER_STATUS.applied ||
        userStatus === USER_STATUS.authorized)) ||
    (status === PROJECT_STATUS.sale &&
      (userStatus === USER_STATUS.authorized ||
        userStatus === USER_STATUS.applied)) ||
    (status === PROJECT_STATUS.distributed &&
      userStatus === USER_STATUS.authorized)

  const needSupport =
    status === PROJECT_STATUS.sale &&
    (userStatus === USER_STATUS.authorized ||
      userStatus === USER_STATUS.applied)

  const isNotBanner =
    location === 'main' ||
    (status === PROJECT_STATUS.lottery &&
      (userStatus === USER_STATUS.unauthorized ||
        userStatus === USER_STATUS.applied)) ||
    (status === PROJECT_STATUS.distributed &&
      userStatus === USER_STATUS.applied)

  if (needAllocation) {
    if (allocation?.min_amount && allocation?.max_amount) {
      return (
        text && (
          <Helper
            variant={isNotBanner ? null : 'banner'}
            location={location}
            column={column}
          >
            {text} ${allocation?.min_amount}-${allocation?.max_amount}
          </Helper>
        )
      )
    }
    return null
  }

  return (
    text && (
      <Helper
        variant={isNotBanner ? null : 'banner'}
        location={location}
        column={column}
      >
        {text}{' '}
        {needDiscord && (
          <>
            <Link
              target="_blank"
              rel="noreferrer"
              href={config.discord_invite_link}
            >
              On the link here
            </Link>
            .
          </>
        )}
        {needSupport && <p>{config.support_mail}</p>}
      </Helper>
    )
  )
}

export default ActionHelper
