import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Email = styled.div`
  width: auto;
  height: auto;
`

export const AuthContainer = styled.div`
  display: flex;
  gap: 8px;
  height: 44px;

  @media (max-width: 1300px) {
    gap: 6px;
  }
`

export const AccountContainer = styled.div`
  display: flex;
  position: relative;
  height: 44px;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
`
