import React from 'react'
import { useConnection } from '../hooks/useConnection'

export const ConnectionContext = React.createContext()

const ConnectionProvider = ({ config, children }) => {
  const {
    connectionState,
    userAddress,
    userBalance,
    stFitfiBalance,
    setUserAddress,
    connectWallet,
    switchChain,
    getStFitfiBalance,
    checkNetwork,
    isLoading,
  } = useConnection({ config })
  return (
    <ConnectionContext.Provider
      value={{
        connectionState,
        userAddress,
        userBalance,
        stFitfiBalance,
        connectWallet,
        switchChain,
        setUserAddress,
        getStFitfiBalance,
        checkNetwork,
        isLoading,
      }}
    >
      {children}
    </ConnectionContext.Provider>
  )
}

export default ConnectionProvider
