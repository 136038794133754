import styled from 'styled-components'
import Button from '../Button'

export const RadioButton = styled(Button)`
  width: 110px;
  height: 60px;
  font-size: 16px;
  border-radius: 8px;
  background: ${({ chosen }) => (chosen ? '#F9D549' : 'transparent')};
  border: ${({ chosen }) =>
    chosen ? '1px solid transparent' : '1px solid #CCCCCC'};
  flex-grow: 1;
  box-sizing: border-box;
  padding: 0;
  &:hover {
    border: 1px solid transparent;
  }
  @media (max-width: 768px) {
    height: 50px;
    font-size: 14px;
    &:hover {
      background: ${({ chosen }) => (chosen ? '#F9D549' : 'transparent')};
      border: ${({ chosen }) =>
        chosen ? '1px solid transparent' : '1px solid #CCCCCC'};
    }
    &:active {
      background: ${({ chosen }) => (chosen ? '#F9D549' : 'transparent')};
      border: ${({ chosen }) =>
        chosen ? '1px solid transparent' : '1px solid #CCCCCC'};
    }
  }
`

export const RadioGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
`
