import { ethers } from 'ethers'
import ContractAbi from './abi/ERC20.json'
import SteplaunchDepositAbi from './abi/SteplaunchDeposit.json'
import StepAppVestingAbi from './abi/SteplaunchVesting.json'

export function getStFitfiToken(contractAddress) {
  try {
    const STFitfiAddress = contractAddress
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const StFitfiToken = new ethers.Contract(
      STFitfiAddress,
      ContractAbi,
      signer,
    )
    return StFitfiToken
  } catch (err) {
    console.log(err)
  }
}

export function getUSDC(contractAddress) {
  try {
    const USDCAddress = contractAddress
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const USDCContract = new ethers.Contract(USDCAddress, ContractAbi, signer)
    return USDCContract
  } catch (err) {
    console.log(err)
  }
}

export function getUSDCBalance(usdcAddress, address) {
  try {
    const USDCContract = getUSDC(usdcAddress)
    return USDCContract.balanceOf(address)
  } catch (err) {
    console.log(err)
  }
}

export function getUSDCAllowance(usdcAddress, address, depositContract) {
  try {
    const USDCContract = getUSDC(usdcAddress)
    return USDCContract.allowance(address, depositContract)
  } catch (err) {
    console.log(err)
  }
}

export function getSteplaunchDeposit(contractAddress) {
  try {
    const steplaunchDepositAddress = contractAddress
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const steplaunchDeposit = new ethers.Contract(
      steplaunchDepositAddress,
      SteplaunchDepositAbi,
      signer,
    )
    return steplaunchDeposit
  } catch (err) {
    console.log(err)
  }
}

export async function isAllocationUsed(contractAddress, allocation) {
  try {
    const steplaunchDepositContract = getSteplaunchDeposit(contractAddress)
    return steplaunchDepositContract.isAllocationUsed(allocation)
  } catch (err) {
    console.log(err)
  }
}

export async function deposit(contractAddress, allocation, amount, signature) {
  try {
    const steplaunchDepositContract = getSteplaunchDeposit(contractAddress)
    return steplaunchDepositContract.deposit(allocation, amount, signature)
  } catch (err) {
    console.log(err)
  }
}

export function getUSDCApprove(usdcAddress, depositContract, amount) {
  try {
    const USDCContract = getUSDC(usdcAddress)
    return USDCContract.approve(depositContract, amount)
  } catch (err) {
    console.log(err)
  }
}

export function getStepAppVesting(contractAddress) {
  try {
    const StepAppVestingAddress = contractAddress
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const StepAppVesting = new ethers.Contract(
      StepAppVestingAddress,
      StepAppVestingAbi,
      signer,
    )
    return StepAppVesting
  } catch (err) {
    console.log(err)
  }
}

export function getReservedVesting(contractAddress, beneficiary) {
  try {
    const StepAppVestingContract = getStepAppVesting(contractAddress)
    return StepAppVestingContract.reservedForBeneficiary(beneficiary)
  } catch (err) {
    console.log(err)
  }
}

export function getReleasedVesting(contractAddress, beneficiary) {
  try {
    const StepAppVestingContract = getStepAppVesting(contractAddress)
    return StepAppVestingContract.releasedToBeneficiary(beneficiary)
  } catch (err) {
    console.log(err)
  }
}

export function getReleasableVesting(contractAddress, beneficiary) {
  try {
    const StepAppVestingContract = getStepAppVesting(contractAddress)
    return StepAppVestingContract.releasableAmount(beneficiary)
  } catch (err) {
    console.log(err)
  }
}

export function release(contractAddress, beneficiary) {
  try {
    const StepAppVestingContract = getStepAppVesting(contractAddress)
    return StepAppVestingContract.release(beneficiary)
  } catch (err) {
    console.log(err)
  }
}

export function getTokenDecimals(contractAddress) {
  try {
    const tokenAddress = contractAddress
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const tokenContract = new ethers.Contract(tokenAddress, ContractAbi, signer)
    return tokenContract.decimals()
  } catch (err) {
    console.log(err)
  }
}
