import styled from 'styled-components'

export const Title = styled.div`
  font-family: 'Roboto Mono', monospace;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin-top: 20px;
`

export const Avatar = styled.img`
  border-radius: 8px;
`

export const Name = styled.div`
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  line-height: 16px;
  font-size: 16px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`

export const Job = styled.div`
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 12px;
  line-height: 5px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin-bottom: 4px;
`

export const Description = styled.div`
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.03em;
  line-height: 17px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  text-align: left;
`

export const Card = styled.div`
  background: rgba(208, 208, 208, 0.2);
  backdrop-filter: blur(28.5px);
  border-radius: 24px;
  display: flex;
  gap: 16px;
  padding: 24px 20px 24px 24px;
  flex-grow: 1;
  max-width: 568px;
  width: 100%;
  @media (max-width: 768px) {
    padding: 16px 14px 16px 16px;
  }
`

export const CardsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  border-box: box-sizing;
`

export const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  box-sizing: border-box;
  padding: 20px 0;
  @media (max-width: 1200px) {
    margin: 0 20px;
    gap: 24px;
  }
`

export const TextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  flex-direction: column;
`

export const NoTeamText = styled.p`
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.03em;
  line-height: 17px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
`

export const WebContainer = styled.div`
  padding: 40px;
  line-height: 200%;
  letter-spacing: 0.03em;

  h1,
  h2,
  h3,
  h4,
  p {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: left;
    :last-child {
      margin-bottom: 0;
    }
  }
  h2 {
    font-weight: 500;
    text-align: justify;
  }
  h1 {
    line-height: 100%;
    text-align: center;
  }
  ul,
  ol {
    margin: 40px 0;
  }
  li {
    text-align: left;
    font-size: 16px;
  }
  h1 {
    font-size: 32px;
  }

  a {
    word-break: break-word;
    text-decoration: none;
    * {
      text-decoration: none;
    }
  }
  @media (max-width: 900px) {
    img {
      width: 100% !important;
      height: unset !important;
    }
  }
`
