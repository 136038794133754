import { useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import {
  ProjectPage,
  Main,
  RecoverPassword,
  SignInUp,
  Help,
  Faq,
  NotFoundPage,
} from '../pages'
import ProtectedRoute from './ProtectedRoute'
import { UserAuthContext } from '../contexts/UserAuthContext'

const RoutesComponent = (props) => {
  const { isLogined } = useContext(UserAuthContext)

  return (
    <Routes>
      <Route element={<Main {...props} />} path="/" exact />
      <Route
        element={<ProjectPage {...props} />}
        path="/projects/:projectName/"
      />
      <Route
        element={<ProjectPage {...props} />}
        path="/projects/:projectName/:tab"
      />
      <Route element={<Faq {...props} />} path="/faq" />
      <Route element={<Help />} path="/help" />
      <Route
        element={
          <ProtectedRoute isLogined={isLogined}>
            <RecoverPassword email />
          </ProtectedRoute>
        }
        path="/recovery-email"
      />
      <Route
        element={
          <ProtectedRoute isLogined={isLogined}>
            <RecoverPassword />
          </ProtectedRoute>
        }
        path="/recover/"
      />
      <Route
        element={
          <ProtectedRoute isLogined={isLogined}>
            <SignInUp />
          </ProtectedRoute>
        }
        path="/signin"
      />
      <Route
        element={
          <ProtectedRoute isLogined={isLogined}>
            <SignInUp signUp />
          </ProtectedRoute>
        }
        path="/signup"
      />
      <Route element={<NotFoundPage />} path="*" />
    </Routes>
  )
}

export default RoutesComponent
