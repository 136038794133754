import {
  Modal,
  ModalContainer,
  Title,
  CheckNetworkContainer,
  NetworkText,
  ButtonContainer,
  VestingText,
  VestingRow,
  VestingContainer,
  TxContainer,
  TXLink,
} from './Modal.styled'
import Button from '../Button'
import { useContext, useEffect, useState } from 'react'
import {
  ConnectionContext,
  UserAuthContext,
  ConfigContext,
} from '../../contexts/'
import { connectionStatesList } from '../../hooks/useConnection'
import ConnectWallet from '../ConnectWallet'
import { shortenAddress, shortenTxHash } from '../../utils/shortenAddress'
import { numberWithCommas } from '../../utils/numberWithCommas'
import { utils } from 'ethers'
import {
  getReservedVesting,
  getReleasedVesting,
  getReleasableVesting,
  release,
  getTokenDecimals,
} from '../../contracts'
import Spinner from '../Spinner'

function ClaimModal({
  isOpen,
  modalRef,
  handleClose,
  project,
  handleOpenInstallMetamask,
}) {
  const [totalAmount, setTotalAmount] = useState('')
  const [alreadyClaimed, setAlreadyClaimed] = useState('')
  const [availableAmount, setAvailableAmount] = useState('')
  const [txProcess, setTxProcess] = useState(false)
  const [txHash, setTxHash] = useState('')
  const [claimDisabled, setClaimDisabled] = useState(true)
  const [decimals, setDecimals] = useState()

  const { userAddress, connectionState, isLoading } =
    useContext(ConnectionContext)
  const { user } = useContext(UserAuthContext)
  const { config } = useContext(ConfigContext)

  const noConnectionError =
    connectionState === connectionStatesList.walletConnectedToProperChain
  const networkError =
    connectionState === connectionStatesList.walletNotConnected ||
    connectionState === connectionStatesList.walletConnectedToOtherChain
  const wrongAddress =
    connectionState === connectionStatesList.walletConnectedToProperChain &&
    user.ethAddress &&
    userAddress !== user.ethAddress

  async function getDecimals() {
    const tokenDecimals = await getTokenDecimals(project.token_address)
    setDecimals(tokenDecimals)
  }

  async function getClaimInfo() {
    const reserved = await getReservedVesting(
      project.vesting_contract,
      userAddress,
    )
    setTotalAmount(reserved)
    const released = await getReleasedVesting(
      project.vesting_contract,
      userAddress,
    )
    setAlreadyClaimed(released)
    const releasable = await getReleasableVesting(
      project.vesting_contract,
      userAddress,
    )
    setAvailableAmount(releasable)
  }

  async function claim() {
    setTxProcess(true)
    await release(project.vesting_contract, userAddress)
      .then((tx) => {
        setTxHash(tx.hash)
        tx.wait()
          .then(async () => {
            await getClaimInfo()
            setTxHash('')
            setTxProcess(false)
          })
          .catch((e) => {
            setTxProcess(false)
            setTxHash('')
          })
      })
      .catch((e) => {
        setTxProcess(false)
      })
  }

  useEffect(() => {
    if (availableAmount && availableAmount.gt(0)) {
      setClaimDisabled(false)
    } else {
      setClaimDisabled(true)
    }
  }, [availableAmount])

  useEffect(() => {
    let timerId
    if (userAddress && isOpen && noConnectionError) {
      getDecimals()
      getClaimInfo()
      timerId = setInterval(getClaimInfo, 20000)
    }
    return () => {
      clearInterval(timerId)
    }
  }, [userAddress, isOpen, noConnectionError]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal isOpen={isOpen}>
      <ModalContainer
        ref={modalRef}
        variant={networkError ? 'check_network' : 'buy_modal'}
      >
        <Title>{networkError ? 'Check network' : 'Your vesting'}</Title>
        {noConnectionError && userAddress && (
          <VestingText> For: {shortenAddress(userAddress)}</VestingText>
        )}
        {networkError && (
          <>
            <CheckNetworkContainer>
              <NetworkText>
                To proceed with your claim, please, connect your wallet to{' '}
                {config.network?.name}
              </NetworkText>
            </CheckNetworkContainer>
            <ButtonContainer>
              <ConnectWallet
                disabled={isLoading}
                variant="black_modal"
                size="modal"
                handleOpenInstallMetamask={handleOpenInstallMetamask}
              />
              <Button size="modal" variant="bordered" onClick={handleClose}>
                Close
              </Button>
            </ButtonContainer>
          </>
        )}
        {wrongAddress && (
          <CheckNetworkContainer>
            <NetworkText>
              Wrong address connected. Please connect to{' '}
              {shortenAddress(user.ethAddress)}.
            </NetworkText>
          </CheckNetworkContainer>
        )}
        {noConnectionError && (
          <>
            <VestingContainer>
              <VestingRow>
                <VestingText>Total</VestingText>
                <VestingText right>
                  {totalAmount && decimals
                    ? numberWithCommas(
                        utils.formatUnits(totalAmount, decimals),
                        2,
                      )
                    : '...'}{' '}
                  {project?.token_symbol}
                </VestingText>
              </VestingRow>
              <VestingRow>
                <VestingText>Claimed</VestingText>
                <VestingText right>
                  {alreadyClaimed && decimals
                    ? numberWithCommas(
                        utils.formatUnits(alreadyClaimed, decimals),
                        2,
                      )
                    : '...'}{' '}
                  {project?.token_symbol}
                </VestingText>
              </VestingRow>
              <VestingRow>
                <VestingText>Available</VestingText>
                <VestingText right>
                  {availableAmount && decimals
                    ? numberWithCommas(
                        utils.formatUnits(availableAmount, decimals),
                        2,
                      )
                    : '...'}{' '}
                  {project?.token_symbol}
                </VestingText>
              </VestingRow>
            </VestingContainer>
            <ButtonContainer>
              {txProcess ? (
                <TxContainer variant="claim">
                  <Spinner size="16px" />
                  {txHash && (
                    <TXLink
                      href={`${config.network?.block_explorer_url}/tx/${txHash}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      TX {shortenTxHash(txHash)}
                    </TXLink>
                  )}
                </TxContainer>
              ) : (
                <Button
                  size="modal"
                  type="submit"
                  onClick={claim}
                  disabled={claimDisabled}
                >
                  Claim
                </Button>
              )}
              <Button size="modal" onClick={handleClose} variant="bordered">
                Close
              </Button>
            </ButtonContainer>
          </>
        )}
      </ModalContainer>
    </Modal>
  )
}

export default ClaimModal
