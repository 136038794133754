import { USER_STATUS } from '../constants'

export function getUserStatus({ isLogined, applied, allocation }) {
  if (isLogined) {
    if (allocation) {
      return USER_STATUS.allocated
    }
    if (applied) {
      return USER_STATUS.applied
    }
    return USER_STATUS.authorized
  } else {
    return USER_STATUS.unauthorized
  }
}
