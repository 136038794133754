import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 24px;
`

export const StakeBannerLink = styled.a`
  text-decoration: none;
  @media (max-width: 768px) {
    width: 100%;
  }
`
