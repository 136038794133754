import React from 'react'
import useAuth from '../hooks/useAuth'

export const UserAuthContext = React.createContext()

const UserAuthProvider = ({ children }) => {
  const {
    user,
    errorText,
    authPopup,
    regUser,
    logOut,
    isLogined,
    login,
    setErrorText,
    linkAddress,
    emailRecoverHandler,
    passwordRecoverHandler,
    ethLinkingStatus,
    setEthLinkingStatus,
    setAuthPopup,
    changePasswordHandler,
    resendActivation,
  } = useAuth()
  return (
    <UserAuthContext.Provider
      value={{
        user,
        errorText,
        authPopup,
        regUser,
        logOut,
        isLogined,
        login,
        setErrorText,
        linkAddress,
        emailRecoverHandler,
        passwordRecoverHandler,
        ethLinkingStatus,
        setEthLinkingStatus,
        setAuthPopup,
        changePasswordHandler,
        resendActivation,
      }}
    >
      {children}
    </UserAuthContext.Provider>
  )
}

export default UserAuthProvider
