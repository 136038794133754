import React, { useContext } from 'react'
import Button from '../Button'
import {
  Email,
  AccountContainer,
  AuthContainer,
  StyledLink,
} from './AuthButtons.styled'
import AccountMenu from '../Menu/AccountMenu'
import useComponentVisible from '../../hooks/useComponentVisible'
import { shortenEmail } from '../../utils/shortenAddress'
import { UserAuthContext } from '../../contexts/UserAuthContext'
import Spinner from '../Spinner'
import { useLocation, useSearchParams } from 'react-router-dom'
import ChangePasswordModal from '../Modals/ChangePasswordModal'

function AuthButtons({ handleOpenInstallMetamask, linkAddressCallback }) {
  const { user, isLogined } = useContext(UserAuthContext)
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const returnTo = searchParams.get('return_to')

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false)
  const {
    ref: passwordModalRef,
    isComponentVisible: modalVisible,
    handleOpen: openModal,
    handleClose: closeModal,
  } = useComponentVisible(false)

  const handleClick = () => {
    setIsComponentVisible(!isComponentVisible)
  }

  return (
    <>
      {isLogined ? (
        <AccountContainer ref={ref}>
          <Button variant="account" onClick={handleClick}>
            <Email>
              {user.email ? (
                shortenEmail(user.email, 14)
              ) : (
                <Spinner size="16px" color="black" />
              )}
            </Email>
          </Button>
          {isComponentVisible && (
            <AccountMenu
              handleClick={handleClick}
              handleOpenInstallMetamask={handleOpenInstallMetamask}
              linkAddressCallback={linkAddressCallback}
              openModal={openModal}
            />
          )}
          <ChangePasswordModal
            ref={passwordModalRef}
            isOpen={modalVisible}
            handleClose={closeModal}
          />
        </AccountContainer>
      ) : (
        <AuthContainer>
          <StyledLink
            to={`/signin${
              returnTo
                ? `?return_to=${returnTo}`
                : `?return_to=${location.pathname}`
            }`}
          >
            <Button variant="sign_in">Sign In</Button>
          </StyledLink>
          <StyledLink
            to={`/signup${
              returnTo
                ? `?return_to=${returnTo}`
                : `?return_to=${location.pathname}`
            }`}
          >
            <Button variant="sign_up">Sign Up</Button>
          </StyledLink>
        </AuthContainer>
      )}
    </>
  )
}

export default AuthButtons
