import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function ScrollToTop(props) {
  const { pathname } = useLocation()

  const tabs = [
    'project-details',
    'team-and-partners',
    'metrics',
    'campaign-details',
  ]

  useEffect(() => {
    let scroll = true
    for (const i in tabs) {
      if (pathname.includes(tabs[i])) {
        scroll = false
      }
    }

    if (scroll) {
      document.getElementById('scroll').scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      })
    }
  }, [pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}
