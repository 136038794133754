import { styled as styledMui } from '@mui/system'
import {
  TabUnstyled,
  TabsUnstyled,
  TabPanelUnstyled,
  TabsListUnstyled,
  buttonUnstyledClasses,
  tabUnstyledClasses,
} from '@mui/base'

export const Tab = styledMui(TabUnstyled)`
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  white-space: nowrap;
  margin-right: 16px;

  color: black;
  cursor: pointer;
  background-color: transparent;
  width: 180px;
  padding: 16px 32px;
  border: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 48px;
  display: flex;
  justify-content: center;
  overflow: hidden;

  @media (min-width: 600px) {
    &:hover {
      background-color: #000000;
      color: white;
    }
  }

  &:focus {
    outline: none;
    overflow: hidden;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #000000;
    color: #fdfdfd;
    border-radius: 64px;
  }

  &.${tabUnstyledClasses.selected}:nth-cild(2) {
    background-color: transparent;
    
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export const TabPanel = styledMui(TabPanelUnstyled)`
  width: 100%;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`

export const TabsList = styledMui(TabsListUnstyled)`
  background-color: transparent;
  display: -webkit-box;
  align-items: center;
  align-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 16px;
  padding-right: 0;
  @media (max-width: 1200px) {
    padding-left: 20px;
    padding-right: 4px;
  }

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px
  }

  @media (max-width: 820px) {
    overflow-x:scroll;
  }
`

export const Tabs = styledMui(TabsUnstyled)`
  width: 100%;
`
