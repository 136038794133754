import { useCallback, useEffect, useState } from 'react'
import {
  changePassword,
  getUser,
  linkEthAddress,
  loginUser,
  logoutUser,
  recoverPassword,
  recoveryEmail,
  registerUser,
} from '../api'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ETH_LINKING_STATUS, KYC_STATUS } from '../constants'
import { unifyStatus } from '../utils/unifyStatus'
import { getErrorMessage } from '../utils/getErrorMessage'
import { resendActivationEmail } from '../api/AuthApi'

const useAuth = () => {
  const [isLogined, setIsLogined] = useState(
    !!localStorage.getItem('authTokens'),
  )

  const [user, setUser] = useState({})
  const [authPopup, setAuthPopup] = useState({
    open: false,
    isError: false,
    message: '',
  })
  const [errorText, setErrorText] = useState('')
  const [ethAddressLinked, setEthAddressLinked] = useState(false)
  const [ethLinkingStatus, setEthLinkingStatus] = useState(
    ETH_LINKING_STATUS.undefined,
  )
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const returnTo = searchParams.get('return_to')
  const goToPrevious = returnTo === '/help' || returnTo?.includes('/projects/')

  const login = async (form) => {
    loginUser(form)
      .then((tokens) => {
        returnTo && goToPrevious ? navigate(returnTo) : navigate('/')
        localStorage.setItem('authTokens', JSON.stringify(tokens))
        setIsLogined(true)
      })
      .catch((e) => {
        setErrorText(getErrorMessage(e))
      })
  }

  const getUserData = async () => {
    getUser()
      .then((userData) => {
        setUser({
          id: userData.id,
          email: userData.email,
          ethAddress: userData.eth_address,
          kycStatus: unifyStatus(userData.kyc_status),
          balance: userData.balance,
          banners: userData.banners,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const linkAddress = async (id, address) => {
    try {
      await linkEthAddress(id, address)
      setEthAddressLinked(true)
      setEthLinkingStatus(ETH_LINKING_STATUS.success)
    } catch (err) {
      if (err.message === 'This field must be unique.') {
        setEthLinkingStatus(ETH_LINKING_STATUS.uniqueError)
      }
      throw new Error(err.message)
    }
  }

  useEffect(() => {
    if (isLogined) {
      getUserData()
    }
  }, [isLogined, ethAddressLinked])

  useEffect(() => {
    if (
      !isLogined ||
      user.kycStatus === KYC_STATUS.approved ||
      user.kycStatus === KYC_STATUS.rejected
    ) {
      return
    }
    const timerId = setInterval(getUserData, 60000)

    return () => {
      clearInterval(timerId)
    }
  }, [isLogined, user.kycStatus])

  function logOut() {
    localStorage.removeItem('authTokens')
    setIsLogined(false)
    setUser({})
    setEthLinkingStatus(ETH_LINKING_STATUS.undefined)
    setEthAddressLinked(false)
  }

  const regUser = async (form) => {
    registerUser(form)
      .then((e) => {
        setErrorText('')
        setAuthPopup((prev) => {
          return {
            ...prev,
            open: true,
          }
        })
        navigate(`/signin${returnTo ? `?return_to=${returnTo}` : ''}`)
      })
      .catch((e) => {
        setErrorText(getErrorMessage(e)[0])
      })
  }

  const resendActivation = useCallback(
    (form) =>
      resendActivationEmail(form)
        .then(() => {
          setErrorText('')
          setAuthPopup((prev) => {
            return {
              ...prev,
              open: true,
            }
          })
        })
        .catch((e) => {
          const errorMessage = getErrorMessage(e)
          setErrorText(
            Array.isArray(errorMessage) ? errorMessage[0] : errorMessage,
          )
        }),
    [],
  )

  const emailRecoverHandler = async (email) => {
    recoveryEmail(email)
      .then(() => {
        setErrorText('')
        setAuthPopup((prev) => {
          return {
            ...prev,
            open: true,
          }
        })
      })
      .catch((e) => {
        setErrorText(getErrorMessage(e)[0])
      })
  }

  const passwordRecoverHandler = async (uidb64, token, password) => {
    recoverPassword(uidb64, token, password)
      .then(() => {
        setErrorText('')
        navigate(`/signin${returnTo ? `?return_to=${returnTo}` : ''}`)
      })
      .catch((e) => {})
  }

  const changePasswordHandler = async ({ oldPassword, password }) => {
    changePassword(oldPassword, password)
      .then(() => {
        setErrorText('')
        logoutUser(JSON.parse(localStorage.getItem('authTokens')).refresh).then(
          () => {
            logOut()
            navigate('/signin')
            setAuthPopup((prev) => {
              return {
                ...prev,
                open: true,
                message: 'your profile password changed successfully',
              }
            })
          },
        )
      })
      .catch((e) => {
        setErrorText(getErrorMessage(e)[0])
      })
  }

  return {
    user,
    errorText,
    regUser,
    isLogined,
    logOut,
    login,
    authPopup,
    setErrorText,
    linkAddress,
    emailRecoverHandler,
    passwordRecoverHandler,
    ethLinkingStatus,
    setEthLinkingStatus,
    setAuthPopup,
    changePasswordHandler,
    resendActivation,
  }
}

export default useAuth
