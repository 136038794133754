import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: relative;

  width: ${({ variant }) => (variant === 'change_password' ? '100%' : 'unset')};
`

export const Label = styled.label`
  font-family: 'Roboto Mono', monospace;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.03em;
`

export const StyledInput = styled.input`
  background: none;
  border: none;
  outline: none;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.03em;

  ::placeholder {
    font-family: 'Roboto Mono', monospace;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.03em;
    color: rgba(0, 0, 0, 0.5);
  }

  width: 100%;
  height: 60px;
  padding: 22px 24px 19px 24px;

  @media (max-width: 768px) {
    padding-left: 16px;
  }

  :focus {
    z-index: 1000;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
`

export const Asterisk = styled.span`
  color: red;
  white-space: pre;
`

export const InputContainer = styled.div`
  display: flex;
  overflow: hidden;

  background: rgb(243, 243, 243);
  border: ${({ status }) => {
    switch (status) {
      case 'error':
        return '1px solid red'
      default:
        return '1px solid #f3f3f3'
    }
  }};
  border-radius: 8px;
  width: 460px;
  height: 60px;

  :focus-within {
    border: 1px solid #ff6363;
    background: white;
    box-sizing: border-box;
    outline: none;
  }

  @media (max-width: 768px) {
    width: 320px;
  }
`

export const IconContainer = styled.button`
  display: flex;
  align-items: center;
  margin-right: 24px;
  cursor: pointer;
  background: none;
  border: none;
  color: black;

  @media (max-width: 768px) {
    margin-right: 16px;
  }
`

export const Help = styled.span`
  font-family: 'Roboto Mono', monospace;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: red;
  position: absolute;
  top: 86px;
`
