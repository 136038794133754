export function shortenAddress(address) {
  return `${address.substring(0, 4)}...${address.substring(address.length - 3)}`
}

export function shortenEmail(email, length) {
  if (email?.length > length) {
    return `${email.substring(0, length)}...`
  }
  return email
}

export function shortenTxHash(hash) {
  return `${hash.substring(0, 6)}...${hash.substring(hash.length - 4)}`
}
