import { useContext } from 'react'
import { UserAuthContext, ConnectionContext } from '../contexts'
import { toHex } from '../utils/decToHex'

function useLinkedAddress({
  config,
  handleOpenInstallMetamask,
  linkAddressCallback,
}) {
  const { user, linkAddress } = useContext(UserAuthContext)
  const {
    userAddress,
    connectWallet,
    getStFitfiBalance,
    switchChain,
    checkNetwork,
  } = useContext(ConnectionContext)

  async function setAddress() {
    try {
      let address = userAddress
      if (!userAddress) {
        address = await connectWallet()
      }
      const chain = checkNetwork()
      if (chain !== toHex(config.network?.chain_id)) {
        await switchChain()
      }
      const balance = await getStFitfiBalance()
      if (balance > config.min_stfitfi_balance) {
        await linkAddress(user.id, address)
      } else {
        linkAddressCallback()
      }
    } catch (err) {
      console.log(err)
    }
  }

  function handleSet() {
    if (window.ethereum) {
      setAddress()
    } else {
      handleOpenInstallMetamask()
    }
  }

  return { handleSet }
}

export default useLinkedAddress
