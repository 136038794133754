import styled from 'styled-components'

export const BannerContainer = styled.div`
  display: flex;
  background: ${({ variant }) => {
    switch (variant) {
      case 'startups':
        return '#F99D49'
      case 'launchpad':
        return '#f9d549'
      case 'stake':
        return '#F96949'
      case 'account':
        return 'transparent'
      default:
        return '#f9d549'
    }
  }};
  border-radius: ${({ variant }) => {
    switch (variant) {
      case 'stake':
        return '16px'
      default:
        return '24px'
    }
  }};
  border: ${({ variant }) => {
    switch (variant) {
      case 'account':
        return '1px solid black'
      default:
        return 'none'
    }
  }};
  box-sizing: border-box;
  padding: 40px;

  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 16px;
  }

  @media (max-width: 768px) {
    padding: 24px 16px;
    margin: ${({ variant }) => {
      switch (variant) {
        case 'stake':
          return '100px 20px 0 20px'
        default:
          return '0 20px'
      }
    }};
  }
`

export const Title = styled.h1`
  font-weight: 500;
  font-size: ${({ variant }) => {
    switch (variant) {
      case 'account':
        return '32px'
      default:
        return '60px'
    }
  }};
  line-height: 100%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: black;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 32px;
  }
`

export const Description = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: ${({ variant }) => {
    switch (variant) {
      case 'community':
        return '160%'
      case 'startups':
        return '160%'
      case 'stake':
        return '16px'
      default:
        return '25.6px'
    }
  }};
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: black;
  margin: 0;

  @media (max-width: 768px) {
    line-height: ${({ variant }) => {
      switch (variant) {
        case 'stake':
          return '22.4px'
        default:
          return '25.6px'
      }
    }};
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  width: auto;
  align-items: flex-end;
  margin-left: auto;

  button {
    height: max-content;
    width: max-content;

    :hover {
      background-color: ${({ variant }) => {
        switch (variant) {
          case 'account':
            return '#f9d549'
          default:
            return 'white'
        }
      }};
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin-left: 0;

    button {
      width: 100%;
      max-width: 400px;
      margin: auto;
    }
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ variant }) => {
    switch (variant) {
      case 'stake':
        return '28px'
      default:
        return '32px'
    }
  }};

  @media (max-width: 768px) {
    gap: ${({ variant }) => {
      switch (variant) {
        case 'stake':
          return '21px'
        default:
          return '16px'
      }
    }};
  }
`
