import React from 'react'
import Banner from '../Banner'
import {
  AboutBannersContainer,
  AboutBlockChainContainer,
  AboutBlockChainDescription,
  AboutBlockChainImg,
  AboutBlockChainTitle,
  AboutSectionContainer,
  AboutSectionDetails,
  SectionDetail,
  SectionDetailDescription,
  SectionDetailTitle,
  StepEcosystemContainer,
  StepEcoSystemDescription,
  StepEcoSystemImg,
  StepEcoSystemTitle,
  AboutBlockChainLink,
} from './AboutSection.styled'
import Button from '../Button'

const AboutSection = () => {
  return (
    <AboutSectionContainer>
      <AboutBannersContainer>
        <Banner
          title="community"
          variant="community"
          description="stake fitfi to get early stage access to projects and their public rounds. enjoy all the benefits of perpetual staking as well."
        />
        <Banner
          title="startups"
          variant="startups"
          description="get rapid adoption within the step community, acquire wide distribution of assets, and seed a supportive base of early product adopters."
        />
        <AboutBlockChainContainer style={{ position: 'relative' }}>
          <AboutBlockChainImg
            src={require('../../images/BlockChain.png')}
            alt="About blockchain"
          />
          <AboutBlockChainTitle>
            A BLOCKCHAIN THAT SERVES WANTS OF LIFE
          </AboutBlockChainTitle>
          <AboutBlockChainDescription>
            STEP NETWORK IS A HIGH-SPEED AND LOW-COST BLOCKCHAIN FOCUSED ON MASS
            SCALE APPLICATIONS FOR CONSUMPTION, LIFESTYLE, AND WELLNESS/FITNESS.
            WITH THE SUPPORT OF GLOBAL BRANDS, CELEBRITY AMBASSADORS, AND A
            ROBUST COMMUNITY, STEP NETWORK IS THE FIRST BLOCKCHAIN AIMED AT
            ENHANCING DAILY LIVES.
          </AboutBlockChainDescription>
          <AboutBlockChainLink
            href="https://stepscan.io/"
            target="_blank"
            rel="noreferrer"
          >
            <Button>Explorer</Button>
          </AboutBlockChainLink>
        </AboutBlockChainContainer>
        <StepEcosystemContainer>
          <StepEcoSystemImg
            src={require('../../images/EcoSystem.png')}
            alt="Step ecosystem"
          />
          <div>
            <StepEcoSystemTitle>
              IN COOPERATION WITH STEP ECOSYSTEM FUND
            </StepEcoSystemTitle>
            <StepEcoSystemDescription>
              STARTUPS LAUNCHING ON STEPLAUNCH CAN ACQUIRE SEED SUPPORT FROM
              STEP ECOSYSTEM FUND, ALONG WITH THE DEEP SUPPORT LINES THE
              ECOSYSTEM FUND OFFERS TO BUILDERS.
            </StepEcoSystemDescription>
          </div>
        </StepEcosystemContainer>
      </AboutBannersContainer>
      <AboutSectionDetails>
        <SectionDetail>
          <SectionDetailTitle>Adoption</SectionDetailTitle>
          <SectionDetailDescription>
            MARKETING PARTNERS, CELEBRITY AMBASSADORS, AND BRAND DEVELOPMENT.
          </SectionDetailDescription>
        </SectionDetail>
        <SectionDetail>
          <SectionDetailTitle>funding</SectionDetailTitle>
          <SectionDetailDescription>
            INSTITUTIONAL PARTNERS, COMMUNITY DISTRIBUTION, AND METRICS
            DEVELOPMENT
          </SectionDetailDescription>
        </SectionDetail>
        <SectionDetail>
          <SectionDetailTitle>LIQUIDITY</SectionDetailTitle>
          <SectionDetailDescription>
            EXCHANGE PARTNERS, MARKET MAKING, AND GO-TO MARKET PLANNING
          </SectionDetailDescription>
        </SectionDetail>
      </AboutSectionDetails>
    </AboutSectionContainer>
  )
}

export default AboutSection
