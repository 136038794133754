import { useState, useEffect } from 'react'
import { utils } from 'ethers'
import { toHex } from '../utils/decToHex'
import { getStFitfiToken } from '../contracts'

export const connectionStatesList = {
  unknown: 'unknown',
  walletNotFound: 'walletNotFound',
  walletNotConnected: 'walletNotConnected',
  walletConnectedToOtherChain: 'walletConnectedToOtherChain',
  walletConnectedToProperChain: 'walletConnectedToProperChain',
}

export const useConnection = ({ config }) => {
  const [connectionState, setConnectionState] = useState(
    connectionStatesList.unknown,
  )
  const [userAddress, setUserAddress] = useState()
  const [userBalance, setUserBalance] = useState()
  const [stFitfiBalance, setStFitfiBalance] = useState()
  const [chainId, setChainId] = useState()
  const [isLoading, setIsLoading] = useState(false)

  async function connectWallet() {
    if (window.ethereum) {
      try {
        setIsLoading(true)
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        })
        setUserAddress(utils.getAddress(accounts[0]))
        setIsLoading(false)
        return utils.getAddress(accounts[0])
      } catch (error) {
        setIsLoading(false)
        throw new Error(error.message)
      }
    }
  }

  async function switchChain() {
    try {
      setIsLoading(true)
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: toHex(config.network?.chain_id) }],
      })
      setIsLoading(false)
      setConnectionState(connectionStatesList.walletConnectedToProperChain)
    } catch (error) {
      if (error.code === 4902 || error.code === -32603) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: toHex(config.network?.chain_id),
                chainName: config.network.name,
                rpcUrls: [config.network.rpc_url],
                nativeCurrency: {
                  name: config.network.native_currency_symbol,
                  symbol: config.network.native_currency_symbol,
                  decimals: 18,
                },
              },
            ],
          })
          setIsLoading(false)
          setConnectionState(connectionStatesList.walletConnectedToProperChain)
        } catch (error) {
          setIsLoading(false)
          throw new Error(error.message)
        }
      } else {
        setIsLoading(false)
        throw new Error(error.message)
      }
    }
  }

  const getBalance = async () => {
    if (userAddress) {
      try {
        const hexBalance = await window.ethereum.request({
          method: 'eth_getBalance',
          params: [userAddress, 'latest'],
        })
        const num = Number(utils.formatEther(hexBalance))
        setUserBalance(num.toFixed(4))
      } catch (error) {
        throw new Error(error.message)
      }
    }
  }

  const getStFitfiBalance = async () => {
    if (userAddress && chainId === toHex(config.network?.chain_id)) {
      try {
        const StFitfiToken = getStFitfiToken(config.network.stfitfi_address)
        const hexStFitfiBalance = await StFitfiToken.balanceOf(userAddress)
        const stFitfi = Number(utils.formatEther(hexStFitfiBalance))
        setStFitfiBalance(stFitfi.toFixed(4))
        return stFitfi.toFixed(4)
      } catch (error) {
        throw new Error(error.message)
      }
    }
  }
  const checkNetwork = async () => {
    try {
      setUserBalance()
      const chain = await window.ethereum.request({
        method: 'eth_chainId',
      })
      setChainId(chain)
      return chain
    } catch (error) {
      throw new Error(error.message)
    }
  }

  function onAddressChanged(accounts) {
    if (accounts.length > 0) {
      setUserBalance()
      setUserAddress(utils.getAddress(accounts[0]))
      getBalance()
      getStFitfiBalance()
    } else {
      setUserBalance()
      setUserAddress()
      setStFitfiBalance()
      setConnectionState(connectionStatesList.walletNotConnected)
    }
  }

  async function connect() {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: 'eth_accounts',
        })
        if (accounts.length > 0) {
          setUserAddress(utils.getAddress(accounts[0]))
          checkNetwork()
        } else {
          setConnectionState(connectionStatesList.walletNotConnected)
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      setConnectionState(connectionStatesList.walletNotFound)
    }
  }

  useEffect(() => {
    if (connectionState === connectionStatesList.unknown) {
      connect()
    }
  }, [userAddress, connectionState, chainId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      userAddress &&
      chainId === toHex(config?.network?.chain_id) &&
      connectionState === connectionStatesList.walletConnectedToProperChain &&
      config?.network
    ) {
      getBalance()
      getStFitfiBalance()
    }
  }, [userAddress, connectionState, chainId, config]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (chainId === toHex(config?.network?.chain_id)) {
      setConnectionState(connectionStatesList.walletConnectedToProperChain)
    } else {
      setConnectionState(connectionStatesList.walletConnectedToOtherChain)
    }
  }, [chainId, userAddress, config])

  useEffect(() => {
    if (userAddress) {
      checkNetwork()
    }
  }, [userAddress])

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', onAddressChanged)
      window.ethereum.on('chainChanged', checkNetwork)
    }
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', onAddressChanged)
        window.ethereum.removeListener('chainChanged', checkNetwork)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    connectionState,
    userAddress,
    userBalance,
    stFitfiBalance,
    chainId,
    isLoading,
    setUserAddress,
    connectWallet,
    switchChain,
    getStFitfiBalance,
    checkNetwork,
  }
}
