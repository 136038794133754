import styled from 'styled-components'
import { SignForm } from '../../pages/SignInUp/SignInUp.styled'

export const Modal = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({ zIndex }) => {
    if (zIndex) {
      return zIndex
    }
    return '100'
  }};

  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s ease;
  visibility: ${({ isOpen }) => {
    if (isOpen) {
      return 'visible'
    }
    return 'hidden'
  }};
  opacity: ${({ isOpen }) => {
    if (isOpen) {
      return '1'
    }
    return '0'
  }};
`

export const ModalContainerHideScrollWrapper = styled.div`
  overflow: hidden;
  border-radius: 24px;
  @media (max-width: 768px) {
    border-radius: 12px;
  }
`

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ variant }) => {
    switch (variant) {
      case 'invest':
        return '16px'
      case 'check_network':
        return '24px'
      case 'buy_modal':
        return '12px'
      default:
        return '20px'
    }
  }};
  width: 100%;
  max-width: 460px;
  background-color: #fdfdfd;
  backdrop-filter: blur(10px);
  padding: 40px 32px;
  position: relative;
  border-radius: 24px;
  max-height: 95vh;
  overflow-y: auto;

  @media (max-width: 768px) {
    max-width: 320px;
    border-radius: 12px;
    padding: 20px;
  }
`

export const Title = styled.h2`
  font-family: 'Roboto Mono', monospace;
  font-weight: 700;
  font-size: 36px;
  line-height: 100%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: black;
  text-align: left;

  @media (max-width: 768px) {
    font-size: ${({ variant }) => {
      switch (variant) {
        case 'invest':
          return '24px'
        default:
          return '32px'
      }
    }};
  }
`

export const Text = styled.p`
  width: 100%;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: ${({ variant }) => {
    switch (variant) {
      case 'invest':
        return '16px'
      default:
        return '20px'
    }
  }};
  line-height: ${({ variant }) => {
    switch (variant) {
      case 'linkAddress':
        return '120%'
      case 'invest':
        return '138%'
      default:
        return '100%'
    }
  }};
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: black;
  text-align: left;
  @media (max-width: 768px) {
    font-size: ${({ variant }) => {
      switch (variant) {
        case 'invest':
          return '14px'
        default:
          return '20px'
      }
    }};
  }
`

export const Link = styled.a`
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: black;
  max-width: 350px;

  @media (max-width: 768px) {
    max-width: 250px;
  }
`

export const ErrorTextContainer = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  margin-bottom: 10px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  gap: 12px;
  width: 100%;
`

export const RadioContainer = styled.div`
  margin: 24px 0;

  @media (max-width: 768px) {
    margin: 16px 0;
  }
`

export const ErrorText = styled.p`
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: red;
  position: absolute;
  top: ${({ variant }) => {
    switch (variant) {
      case 'invest':
        return 'unset'
      default:
        return '48px'
    }
  }};
  bottom: ${({ variant }) => {
    switch (variant) {
      case 'invest':
        return '138px'
      default:
        return 'unset'
    }
  }};
`

export const Banner = styled.div`
  border-radius: 8px;
  background: #f3f3f3;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 8px;

  @media (max-width: 768px) {
    padding: 16px;
  }
`

export const LinkModalErrorText = styled.p`
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: red;
`

export const LinkModalErrorContainer = styled.div`
  margin: 0;
  padding: 0;
  height: 12px;
  @media (max-width: 768px) {
    height: 24px;
  }
`

export const ModalInput = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  color: rgba(0, 0, 0, 0.8);
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  background: transparent;
  width: 100%;
  &:focus {
    outline: none;
  }
`

export const InputContainer = styled.div`
  position: relative;
  background: #f3f3f3;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 20px 24px;
`

export const CalculationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 38px 0;
  gap: 12px;
`

export const CalculationInfo = styled(Text)`
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  font-family: 'Roboto Mono', monospace;
  text-align: right;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: black;
`

export const CheckNetworkContainer = styled.div`
  background: #f0e3e3;
  padding: 24px;
  border-radius: 8px;
`

export const NetworkText = styled.p`
  color: #dc5757;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-family: 'Roboto Mono', monospace;
`

export const BuyText = styled.p`
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
`

export const CoinContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 23px;
`

export const CalculationInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CalculationInfoMaximum = styled.span`
  color: rgba(79, 121, 228, 1);
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const ErrorInfo = styled(CalculationInfo)`
  text-align: left;
  color: #dc5757;
`

export const AccentText = styled.span`
  color: #dc5757;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  text-transform: inherit;
  line-height: inherit;
  letter-spacing: inherit;
`

export const TXLink = styled.a`
  color: black;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`

export const TxContainer = styled.div`
  background: #f9d549;
  width: 100%;
  padding: 21px 100px;
  display: flex;
  justify-content: center;
  border-radius: 64px;
  letter-spacing: 0.03em;
  font-family: 'Roboto Mono', monospace;
  border: none;
  gap: 5px;
  @media (max-width: 768px) {
    padding: ${({ variant }) => {
      if (variant === 'claim') {
        return '21px 50px'
      }
      return '13px 60px'
    }};
  }
`

export const VestingRow = styled.div`
  display: flex;
  justify-content: space-between;
`

export const VestingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 12px 0;
`

export const VestingText = styled.p`
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: ${({ right }) => (right ? 'right' : 'left')};
  @media (max-width: 768px) {
    max-width: 200px;
  }
`

export const ChangePasswordForm = styled(SignForm)`
  width: 100%;
  height: 100%;

  #input {
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
  }
`
