import styled from 'styled-components'

export const MenuBlock = styled.div`
  position: absolute;
  right: 0;
  top: 52px;
  background: #f3f3f3;
  border-radius: 24px;
  width: 280px;
  height: auto;
  padding: 8px 20px;
  gap: 8px;
  z-index: 1;

  animation: openMenu 300ms ease-in-out forwards;
  transform-origin: top center;

  @keyframes openMenu {
    0% {
      transform: scaleY(0);
    }

    100% {
      transform: scaleY(1);
    }
  }

  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    top: unset;
    width: 100%;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    padding-bottom: 5px;
    transform-origin: bottom center;
  }
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 44px;
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`

export const Divider = styled.hr`
  opacity: 0.15;
  border-top: 1px solid #000000;
  border-bottom: none;
  margin: 8px 0;
`
export const Key = styled.span`
  color: #7a7a7a;
  margin-right: auto;
`

export const Value = styled.span`
  display: flex;
  align-items: center;
`

export const EmailValue = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
`

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;

  cursor: pointer;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
  &:active {
    transform: scale(0.9);
  }
`

export const Background = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

export const CloseButton = styled.button`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 24px;
    border: 1px solid white;
    width: 60px !important;
    height: 60px !important;
    position: absolute;
    right: 20px;
    top: -84px;
    background: none;
    cursor: pointer;

    svg {
      color: white;
    }
  }
`

export const LogOut = styled.button`
  width: 100% !important;
  height: 100% !important;
  cursor: pointer;
  border: none;
  background: none;

  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  text-align: left;
  color: black;
`

export const ChangePassword = styled(LogOut)``
