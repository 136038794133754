import {
  StatisticsBlock,
  SubscribeBlock,
  InputLabel,
  SignUpLink,
  StatisticsLink,
} from './Statistics.styled'
import Button from '../Button'
import React, { useContext } from 'react'

import { UserAuthContext, ConfigContext } from '../../contexts'

function Statistics() {
  const { isLogined } = useContext(UserAuthContext)
  const { config } = useContext(ConfigContext)

  return (
    <StatisticsBlock>
      <SubscribeBlock>
        <InputLabel>Wanna stay tuned?</InputLabel>
        {!isLogined && (
          <SignUpLink to="/signup">
            <Button variant="black">Register</Button>
          </SignUpLink>
        )}
        <StatisticsLink
          target="_blank"
          rel="noreferrer"
          href={config?.discord_invite_link}
        >
          <Button variant="black">Join discord</Button>
        </StatisticsLink>
      </SubscribeBlock>
    </StatisticsBlock>
  )
}

export default React.memo(Statistics)
