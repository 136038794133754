import {
  Modal,
  ModalContainer,
  Title,
  ModalInput,
  InputContainer,
  ButtonContainer,
  CalculationContainer,
  CalculationInfo,
  CheckNetworkContainer,
  NetworkText,
  BuyText,
  CoinContainer,
  CalculationInfoContainer,
  CalculationInfoMaximum,
  ErrorInfo,
  TXLink,
  TxContainer,
} from './Modal.styled'
import Button from '../Button'
import { useState, useContext, useEffect } from 'react'
import {
  ConnectionContext,
  UserAuthContext,
  ConfigContext,
} from '../../contexts'
import { connectionStatesList } from '../../hooks/useConnection'
import ConnectWallet from '../ConnectWallet'
import Spinner from '../Spinner'
import { shortenAddress, shortenTxHash } from '../../utils/shortenAddress'
import { numberWithCommas } from '../../utils/numberWithCommas'
import {
  getUSDCBalance,
  getUSDCAllowance,
  isAllocationUsed,
  getUSDCApprove,
  deposit,
  getTokenDecimals,
} from '../../contracts'
import { BigNumber, ethers } from 'ethers'

function BuyModal({
  isOpen,
  modalRef,
  handleClose,
  project,
  handleOpenInstallMetamask,
}) {
  const [allocationUsed, setAllocationUsed] = useState(false)
  const [USDCbalance, setUSDCBalance] = useState(0)
  const [isApproved, setIsApproved] = useState(false)
  const [receivedAmount, setReceivedAmount] = useState(0)
  const [amount, setAmount] = useState('')
  const [error, setError] = useState('')
  const [txProcess, setTxProcess] = useState(false)
  const [txHash, setTxHash] = useState('')
  const [stableCoinDecimals, setStableCoinDecimals] = useState()
  const { userAddress, connectionState, isLoading } =
    useContext(ConnectionContext)
  const { user } = useContext(UserAuthContext)
  const { config } = useContext(ConfigContext)

  const signature = project.allocation.signature
  const depositAddress =
    project.deposit_contract || config.network?.default_deposit_address

  const allocationToBeSent = {
    projectId: project.allocation.project_id,
    beneficiary: project.allocation.beneficiary,
    depositToken: config.network?.usdc_address,
    minAmount: project.allocation.min_amount,
    maxAmount: project.allocation.max_amount,
    deadline: project.allocation.deadline,
    hardCap: project.allocation.hard_cap,
  }

  const noConnectionError =
    connectionState === connectionStatesList.walletConnectedToProperChain &&
    user.ethAddress &&
    userAddress === user.ethAddress
  const networkError =
    connectionState === connectionStatesList.walletNotConnected ||
    connectionState === connectionStatesList.walletConnectedToOtherChain
  const wrongAddress =
    connectionState === connectionStatesList.walletConnectedToProperChain &&
    user.ethAddress &&
    userAddress !== user.ethAddress

  async function getAllowance() {
    const allowance = await getUSDCAllowance(
      config.network?.usdc_address,
      user.ethAddress,
      depositAddress,
    )
    const maxAmount = BigNumber.from(project.allocation.max_amount)
    if (maxAmount.gt(allowance)) {
      setIsApproved(false)
    } else {
      setIsApproved(true)
    }
  }

  async function getStableCoinDecimals(stableCoinAddress) {
    const stableCoinDec = await getTokenDecimals(stableCoinAddress)
    setStableCoinDecimals(stableCoinDec)
  }

  async function getInfo() {
    const allocationIsUsed = await isAllocationUsed(depositAddress, allocationToBeSent)
    setAllocationUsed(allocationIsUsed)
    const balance = await getUSDCBalance(
      config.network?.usdc_address,
      user.ethAddress,
    )
    setUSDCBalance(balance)
    await getAllowance()
  }

  function clearError() {
    setError('')
  }

  function handleInput(e) {
    const value = e.target.value
    if (value.length <= 18 && /^\d*(\.\d{0,6})?$/.test(value)) {
      setAmount(value)
    }
  }

  function changeReceivedAmount(value) {
    if (Number(value) > 0) {
      const tokenAmount = Number(value) * Number(project.token_price_usd)
      setReceivedAmount(tokenAmount)
    } else {
      setReceivedAmount(0)
    }
  }

  function setMax() {
    clearError()
    const maxAmount = BigNumber.from(project.allocation.max_amount)
    if (USDCbalance) {
      const maxNum = USDCbalance.gt(maxAmount) ? maxAmount : USDCbalance
      const formattedMaxNum = ethers.utils.formatUnits(
        maxNum,
        stableCoinDecimals
      )
      setAmount(formattedMaxNum)
      changeReceivedAmount(formattedMaxNum)
    } else {
      setAmount('0')
    }
  }

  async function approveUSDC() {
    setTxProcess(true)
    await getUSDCApprove(
      config.network?.usdc_address,
      depositAddress,
      ethers.constants.MaxUint256,
    )
      .then((tx) => {
        setTxHash(tx.hash)
        tx.wait()
          .then(async () => {
            await getInfo()
            setTxHash('')
            setTxProcess(false)
          })
          .catch((e) => {
            setTxProcess(false)
            setTxHash('')
          })
      })
      .catch((e) => {
        setTxProcess(false)
      })
  }

  async function depositToken() {
    setTxProcess(true)
    const USDCAmount = ethers.utils.parseUnits(
      amount,
      stableCoinDecimals,
    )
    await deposit(depositAddress, allocationToBeSent, USDCAmount, signature)
      .then((tx) => {
        setTxHash(tx.hash)
        tx.wait()
          .then(async () => {
            await getInfo()
            setTxHash('')
            setTxProcess(false)
          })
          .catch((e) => {
            setTxProcess(false)
            setTxHash('')
          })
      })
      .catch((e) => {
        setTxProcess(false)
      })
  }

  useEffect(() => {
    if (config.network?.usdc_address) {
      getStableCoinDecimals(config.network?.usdc_address)
    }
  }, [config])

  useEffect(() => {
    if (amount) {
      clearError()
      const valueWei = ethers.utils.parseUnits(
        amount,
        stableCoinDecimals,
      )
      const maxAmount = BigNumber.from(project.allocation.max_amount)
      const minAmount = BigNumber.from(project.allocation.min_amount)
      if (valueWei.gt(USDCbalance)) {
        setError('Insufficient USDT balance')
      } else if (valueWei.gt(maxAmount)) {
        setError('Allocation exceeded')
      } else if (valueWei.lt(minAmount)) {
        setError(
          `Your allocation shouldn't be less than ${ethers.utils.formatUnits(
            project.allocation.min_amount,
            stableCoinDecimals,
          )} USDT`,
        )
      }
      changeReceivedAmount(amount)
    }
  }, [amount]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (noConnectionError && isOpen) {
      getInfo()
    }
  }, [connectionState, noConnectionError, networkError, wrongAddress, isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal isOpen={isOpen} variant="buy_modal">
      <ModalContainer
        ref={modalRef}
        variant={noConnectionError ? 'buy_modal' : 'check_network'}
      >
        <Title>{noConnectionError ? 'Buy' : 'Check network'}</Title>
        {networkError && (
          <>
            <CheckNetworkContainer>
              <NetworkText>
                To proceed with your purchase, please, connect your wallet to{' '}
                {config.network?.name}
              </NetworkText>
            </CheckNetworkContainer>
            <ButtonContainer>
              <ConnectWallet
                disabled={isLoading}
                variant="black_modal"
                size="modal"
                handleOpenInstallMetamask={handleOpenInstallMetamask}
              />
              <Button size="modal" variant="bordered" onClick={handleClose}>
                Close
              </Button>
            </ButtonContainer>
          </>
        )}
        {wrongAddress && (
          <>
            <CheckNetworkContainer>
              <NetworkText>
                Wrong address connected. Please connect to{' '}
                {shortenAddress(user.ethAddress)}.
              </NetworkText>
            </CheckNetworkContainer>
            <Button size="modal" variant="bordered" onClick={handleClose}>
              Yep, I get it.
            </Button>
          </>
        )}
        {noConnectionError && (
          <>
            <BuyText>
              YOUR ALLOCATION IS $
              {stableCoinDecimals ? ethers.utils.formatUnits(
                project.allocation.min_amount,
                stableCoinDecimals,
              ) : '...'}{' '}
              - $
              {stableCoinDecimals ? ethers.utils.formatUnits(
                project.allocation.max_amount,
                stableCoinDecimals,
              ) : '...'}
            </BuyText>
            {!allocationUsed ? (
              <>
                <CalculationContainer>
                  <BuyText>Spend</BuyText>
                  <InputContainer>
                    <ModalInput
                      type="number"
                      placeholder="0.00"
                      value={amount}
                      onChange={handleInput}
                      pattern="^\d*(\.\d{0,6})?$"
                    />
                    <CoinContainer>
                      <BuyText>USDT</BuyText>
                      <img
                        src={require('../../images/USDCCoin.svg').default}
                        alt="USDTCoin"
                        width="32px"
                      />
                    </CoinContainer>
                  </InputContainer>
                  <CalculationInfoContainer>
                    <ErrorInfo>{error}</ErrorInfo>
                    <CalculationInfo>
                      Balance:
                      <br />
                      {stableCoinDecimals ? ethers.utils.formatUnits(
                        USDCbalance,
                        stableCoinDecimals,
                      ) : '...'}{' '}
                      USDC
                      <br />
                      <CalculationInfoMaximum onClick={setMax}>
                        Maximum
                      </CalculationInfoMaximum>
                    </CalculationInfo>
                  </CalculationInfoContainer>
                </CalculationContainer>
                <BuyText>
                  You will receive{' '}
                  {error || !Number(amount) ? 0 : numberWithCommas(receivedAmount, 2) }{' '}
                  {project.token_symbol}
                </BuyText>
                <ButtonContainer>
                  {txProcess ? (
                    <TxContainer>
                      <Spinner size="16px" />
                      {txHash && (
                        <TXLink
                          href={`${config.network?.block_explorer_url}/tx/${txHash}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          TX {shortenTxHash(txHash)}
                        </TXLink>
                      )}
                    </TxContainer>
                  ) : isApproved ? (
                    <Button
                      size="modal"
                      onClick={depositToken}
                      disabled={error || !Number(amount) || !USDCbalance}
                    >
                      Buy
                    </Button>
                  ) : (
                    <Button
                      size="modal"
                      onClick={approveUSDC}
                      disabled={error || !Number(amount)}
                    >
                      Approve
                    </Button>
                  )}

                  <Button size="modal" variant="bordered" onClick={handleClose}>
                    Close
                  </Button>
                </ButtonContainer>
              </>
            ) : (
              <>
                <BuyText>You have already used your allocation.</BuyText>
                <Button size="modal" variant="bordered" onClick={handleClose}>
                  Close
                </Button>
              </>
            )}
          </>
        )}
      </ModalContainer>
    </Modal>
  )
}

export default BuyModal
