import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Help } from '../../components/Input/Input.styled'

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(
    100vh - 80px - 144px
  ); // 80px - header height, 144px - footer+margin height
  padding: 60px 20px 55px 20px;

  @media (max-width: 768px) {
    height: auto;
  }
`

export const SignForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  width: 460px;

  @media (max-width: 768px) {
    gap: 36px;
    width: 320px;
  }
`

export const Header = styled.h1`
  font-family: 'Roboto Mono', monospace;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 36px;
  line-height: 100%;
  letter-spacing: 0.03em;
  margin-bottom: 4px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 24px;
  margin-top: 4px;

  button {
    flex-grow: 1;
    height: 60px;
    border-radius: 64px;
    font-size: 12px;
    max-width: 218px;
  }

  @media (max-width: 768px) {
    gap: 10px;
    button {
      max-width: 155px;
    }
  }
`

export const OtherAction = styled.p`
  font-family: 'Roboto Mono', monospace;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.03em;
  margin-top: 32px;

  a {
    color: #989898;
  }
`

export const PasswordContainer = styled.div`
  position: relative;
  width: ${({ variant }) => (variant === 'change_password' ? '100%' : 'unset')};
`

export const Forgot = styled(Link)`
  color: #989898;
  position: absolute;
  font-family: 'Roboto Mono', monospace;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.03em;
  right: 0;
  top: 0;
  cursor: pointer;
  text-decoration: underline;
`

export const StyledHelp = styled(Help)`
  @media (max-width: 768px) {
    text-align: center;
  }
`
