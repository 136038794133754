import React from 'react'
import { StyledButton } from './ActionButton.styled'

function ClaimButton({ text, variant, location, handleOpenClaimModal }) {
  return (
    text && (
      <StyledButton
        location={location}
        variant={variant}
        onClick={handleOpenClaimModal}
      >
        {text}
      </StyledButton>
    )
  )
}

export default ClaimButton
