import fetch from './fetch'

export async function changePassword(oldPassword, password) {
  return await fetch('/auth/password/', {
    method: 'PATCH',
    body: JSON.stringify({ old_password: oldPassword, password }),
    withAuth: true,
  })
    .then(async (response) => {
      if (response.ok) {
        return response
      }
      const err = await response.json()
      throw new Error(JSON.stringify(err.old_password || err.password))
    })
    .then((response) => response.json())
    .catch((err) => {
      throw new Error(err)
    })
}

export async function recoveryEmail(email) {
  return await fetch('/auth/password/recovery-email/', {
    method: 'POST',
    body: JSON.stringify({ email }),
  })
    .then(async (response) => {
      if (response.ok) {
        return response
      }
      const err = await response.json()
      throw new Error(JSON.stringify(err.email))
    })
    .then((response) => response.json())
    .catch((err) => {
      throw new Error(err)
    })
}

export async function recoverPassword(uidb64, token, password) {
  return await fetch('/auth/password/recover/', {
    method: 'POST',
    body: JSON.stringify({ uidb64, token, password }),
  })
    .then(async (response) => {
      if (response.ok) {
        return response
      }
      const err = await response.json()
      throw new Error(JSON.stringify(err.email))
    })
    .then((response) => response.json())
    .catch((err) => {
      throw new Error(err)
    })
}
