import React, { useContext, useState, useReducer, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Header from './components/Header'
import RoutesComponent from './Routes'
import Footer from './components/Footer'
import BurgerMenu from './components/BurgerMenu'
import ConnectionProvider from './contexts/ConnectionContext'
import NotifBanners from './components/NotifBanners'
import UserAuthProvider from './contexts/UserAuthContext'
import { ConfigContext } from './contexts/ConfigContext'
import useComponentVisible from './hooks/useComponentVisible'
import {
  LinkAddressModal,
  ClaimModal,
  InvestModal,
  BuyModal,
  InstallMetamaskModal,
} from './components/Modals'

const AppContainer = styled.div`
  min-height: 100vh;
  height: 100vh;
  background: white;
  max-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: ${({ menuOpen }) => {
    switch (menuOpen) {
      case true:
        return 'hidden'
      default:
        return 'auto'
    }
  }};
`
function App() {
  const { pathname } = useLocation()
  const { config } = useContext(ConfigContext)
  const [pageProject, setPageProject] = useState()
  const [carouselProject, setCarouselProject] = useState(null)
  const {
    ref: metamaskModalRef,
    isComponentVisible: isMetamaskModalVisible,
    handleClose: metamaskModalClose,
    handleOpen: handleOpenInstallMetamask,
  } = useComponentVisible(false)
  const {
    ref: linkModalref,
    isComponentVisible: isLinkModalVisible,
    handleClose: linkModalClose,
    handleOpen: linkModalOpen,
  } = useComponentVisible(false)
  const {
    ref: burgerMenuRef,
    isComponentVisible: isBurgerMenuVisible,
    handleClose: burgerMenuClose,
    handleOpen: burgerMenuOpen,
  } = useComponentVisible(false)
  const {
    ref: claimModalRef,
    isComponentVisible: isClaimModalVisible,
    handleClose: claimModalClose,
    handleOpen: claimModalOpen,
  } = useComponentVisible(false)
  const {
    ref: investModalRef,
    isComponentVisible: isInvestModalVisible,
    handleClose: investModalClose,
    handleOpen: investModalOpen,
  } = useComponentVisible(false)
  const {
    ref: buyModalRef,
    isComponentVisible: isBuyModalVisible,
    handleClose: buyModalClose,
    handleOpen: buyModalOpen,
  } = useComponentVisible(false)

  const [forcedAfterApply, forceUpdateAfterApply] = useReducer((x) => x + 1, 0)

  useEffect(() => {
    if (!pathname.includes('project')) {
      setPageProject()
    }
  }, [pathname])

  return (
    <UserAuthProvider>
      <ConnectionProvider config={config}>
        <AppContainer menuOpen={isBurgerMenuVisible} id="scroll">
          <div>
            <Header
              handleMenuClose={burgerMenuClose}
              handleMenuOpen={burgerMenuOpen}
              menuOpen={isBurgerMenuVisible}
              handleOpenInstallMetamask={handleOpenInstallMetamask}
              linkAddressCallback={linkModalOpen}
            />
            <BurgerMenu
              menuRef={burgerMenuRef}
              isOpen={isBurgerMenuVisible}
              handleClose={burgerMenuClose}
              handleOpenInstallMetamask={handleOpenInstallMetamask}
            />
            <InstallMetamaskModal
              isOpen={isMetamaskModalVisible}
              onClose={metamaskModalClose}
              modalRef={metamaskModalRef}
            />
            <NotifBanners
              handleOpenInstallMetamask={handleOpenInstallMetamask}
              linkAddressCallback={linkModalOpen}
            />
            <LinkAddressModal
              isOpen={isLinkModalVisible}
              onClose={linkModalClose}
              modalRef={linkModalref}
            />
            {(pathname === '/' ? carouselProject : pageProject) && (
              <>
                <ClaimModal
                  isOpen={isClaimModalVisible}
                  handleClose={claimModalClose}
                  modalRef={claimModalRef}
                  project={pathname === '/' ? carouselProject : pageProject}
                  handleOpenInstallMetamask={handleOpenInstallMetamask}
                />
                <InvestModal
                  isOpen={isInvestModalVisible}
                  modalRef={investModalRef}
                  amountChoices={
                    pathname === '/'
                      ? carouselProject?.amount_choices
                      : pageProject?.amount_choices
                  }
                  handleClose={investModalClose}
                  projectName={
                    pathname === '/'
                      ? carouselProject?.name.toLowerCase()
                      : pageProject?.name.toLowerCase()
                  }
                  forceUpdateAfterApply={forceUpdateAfterApply}
                />
                {(pathname === '/'
                  ? carouselProject?.allocation
                  : pageProject?.allocation) && (
                      <BuyModal
                        isOpen={isBuyModalVisible}
                        handleClose={buyModalClose}
                        modalRef={buyModalRef}
                        project={
                          pathname === '/' ? carouselProject : pageProject
                        }
                        handleOpenInstallMetamask={handleOpenInstallMetamask}
                      />
                    )}
              </>
            )}
            <RoutesComponent
              claimModalOpen={claimModalOpen}
              investModalOpen={investModalOpen}
              buyModalOpen={buyModalOpen}
              pageProject={pageProject}
              setPageProject={setPageProject}
              setCarouselProject={setCarouselProject}
              forcedAfterApply={forcedAfterApply}
            />
          </div>
          <Footer />
        </AppContainer>
      </ConnectionProvider>
    </UserAuthProvider>
  )
}

export default App
