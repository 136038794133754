import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Button from '../../components/Button'
import EmailInput from '../../components/EmailInput'
import { Help } from '../../components/Input/Input.styled'
import NotificationPopUp from '../../components/NotificationPopUp'
import PasswordInputs from '../../components/PasswordInputs'
import { PasswordRecoveryNotification } from '../../constants'
import { UserAuthContext } from '../../contexts/UserAuthContext'
import {
  ButtonsContainer,
  Header,
  PageContainer,
} from '../SignInUp/SignInUp.styled'
import { RecoverForm } from './RecoverPassword.styled'

const RecoverPassword = ({ email }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const returnTo = searchParams.get('return_to')

  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: {
      ...(email ? { email: '' } : { password: '', repeat: '' }),
    },
    mode: 'onChange',
  })

  const { errorText, emailRecoverHandler, passwordRecoverHandler } =
    useContext(UserAuthContext)

  const submitEmail = ({ email }) => {
    emailRecoverHandler(email)
  }

  const submitPassword = ({ password }) => {
    const uidb64 = searchParams.get('uidb64')
    const token = searchParams.get('token')
    passwordRecoverHandler(uidb64, token, password)
  }

  return (
    <PageContainer>
      <RecoverForm
        id="recover"
        noValidate
        onSubmit={handleSubmit(email ? submitEmail : submitPassword)}
      >
        <Header>{email ? 'Forgot password?' : 'Set your new password'}</Header>
        {email ? (
          <EmailInput errors={errors} register={register} />
        ) : (
          <PasswordInputs
            errors={errors}
            register={register}
            watch={watch}
            condition={!email}
            setError={setError}
            clearError={clearErrors}
          />
        )}

        <ButtonsContainer>
          <Button disabled={!isDirty || !isValid} type="submit" form="recover">
            {email ? 'send recovery link' : 'Set password'}
          </Button>
          <Button
            variant="transparent"
            type="reset"
            onClick={() =>
              navigate(`/signin${returnTo ? `?return_to=${returnTo}` : ''}`)
            }
          >
            Cancel
          </Button>
        </ButtonsContainer>
        {errorText && <Help style={{ position: 'unset' }}>{errorText}</Help>}
      </RecoverForm>
      <NotificationPopUp
        message={PasswordRecoveryNotification}
        variant="registration"
      />
    </PageContainer>
  )
}

export default RecoverPassword
