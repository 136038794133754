import {
  Title,
  Description,
  ButtonsContainer,
  BannerContainer,
  TextContainer,
} from './Banner.styled'

function Banner({ title, description, buttons, variant }) {
  return (
    <BannerContainer variant={variant}>
      <TextContainer variant={variant}>
        <Title variant={variant}>{title}</Title>
        <Description variant={variant}>{description}</Description>
      </TextContainer>
      <ButtonsContainer variant={variant}>{buttons}</ButtonsContainer>
    </BannerContainer>
  )
}

export default Banner
