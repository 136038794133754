import React from 'react'
import ProjectInfo from '../ProjectInfo'
import { InfoContainer } from './ProjectDetails.styled'
import ProjectAbout from '../ProjectAbout'

function ProjectDetails({ startDate, endDate, about }) {
  return (
    <>
      <ProjectAbout about={about} />
      {startDate && endDate && (
        <InfoContainer>
          <ProjectInfo startDate={startDate} endDate={endDate} />
        </InfoContainer>
      )}
    </>
  )
}

export default ProjectDetails
