import React, { useEffect } from 'react'
import ProjectTabs from '../../components/ProjectTabs'
import { StyledTag } from '../../components/Tag/Tag.styled'
import {
  Description,
  Title,
  SocialContainer,
  SocialText,
  ProjectPageContainer,
  BackButtonLink,
  ProjectImage,
  ProjectInfoCard,
  TagsStatusContainer,
  TagsContainer,
  Status,
  TabsContainer,
  ActionButtonContainer,
} from './ProjectPage.styled'
import SocialButtons from '../../components/SocialButtons'
import { useParams } from 'react-router-dom'
import { getProjectByName } from '../../api/'
import Spinner from '../../components/Spinner'
import ActionButton from '../../components/ActionButton'
import ActionHelper from '../../components/ActionHelper'

import { PROJECT_STATUS } from '../../constants'

function ProjectPage({
  claimModalOpen,
  investModalOpen,
  buyModalOpen,
  pageProject,
  setPageProject,
  forcedAfterApply,
}) {
  const { projectName } = useParams()

  useEffect(() => {
    getProjectByName(projectName)
      .then((project) => {
        setPageProject(project)
      })
      .catch((e) => console.log(e))
  }, [projectName, forcedAfterApply]) // eslint-disable-line react-hooks/exhaustive-deps

  function TagsComponents() {
    const tags = pageProject.tags.map((value) => {
      return <StyledTag key={value}>{value}</StyledTag>
    })
    return tags
  }

  return (
    <>
      {pageProject ? (
        <>
          <ProjectPageContainer>
            <BackButtonLink to="/">Back</BackButtonLink>
            <ProjectImage src={pageProject.avatar} alt="projectImage" />
            <ProjectInfoCard>
              <TagsStatusContainer>
                <TagsContainer>
                  {pageProject && <TagsComponents />}
                </TagsContainer>
                <Status status={pageProject.status}>
                  {pageProject.status === PROJECT_STATUS.distributed
                    ? 'Raised'
                    : pageProject.status}
                </Status>
              </TagsStatusContainer>
              <Title>{pageProject.name}</Title>
              <Description dangerouslySetInnerHTML={{ __html: pageProject.description }} />
              <SocialContainer>
                <SocialText>Our social</SocialText>
                <SocialButtons
                  telegram={pageProject.telegram}
                  twitter={pageProject.twitter}
                  instagram={pageProject.instagram}
                  youtube={pageProject.youtube}
                  website={pageProject.website}
                  facebook={pageProject.facebook}
                  github={pageProject.github}
                  discord={pageProject.discord}
                  medium={pageProject.medium}
                  linkedin={pageProject.linkedin}
                  variant="projectPage"
                />
              </SocialContainer>
              <ActionButtonContainer>
                <ActionHelper
                  status={pageProject.status}
                  applied={pageProject.applied}
                  amount={pageProject.application?.amount}
                  location="project"
                  allocation={pageProject.allocation}
                />
                <ActionButton
                  status={pageProject.status}
                  applied={pageProject.applied}
                  handleOpenInvestModal={investModalOpen}
                  handleOpenBuyModal={buyModalOpen}
                  handleOpenClaimModal={claimModalOpen}
                  amount={pageProject.application?.amount}
                  allocation={pageProject.allocation}
                />
              </ActionButtonContainer>
            </ProjectInfoCard>
          </ProjectPageContainer>
          <TabsContainer>
            <ProjectTabs
              name={pageProject.name}
              startDate={pageProject.start_date}
              endDate={pageProject.end_date}
              about={pageProject.details}
              campaign={pageProject.campaign_details}
              team={pageProject.team}
              partners={pageProject.partners}
              tokenDistribution={pageProject.documented_token_distribution}
              tokenReleaseSchedule={pageProject.token_release_schedule}
              salesRoundDetails={pageProject.sales_round_details}
              ath={pageProject.ath}
              roi={pageProject.roi}
            />
          </TabsContainer>
        </>
      ) : (
        <Spinner />
      )}
    </>
  )
}

export default ProjectPage
