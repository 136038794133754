import styled from 'styled-components'

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  white-space: nowrap;
  background: ${({ variant }) => {
    switch (variant) {
      case 'transparent':
        return '#FFFFFF'
      case 'bordered':
        return 'transparent'
      case 'black':
        return 'black'
      case 'black_submit':
        return 'black'
      case 'black_set':
        return 'black'
      case 'header':
        return 'transparent'
      case 'sign_in':
        return 'transparent'
      case 'acc_menu':
        return 'black'
      case 'burger':
        return 'transparent'
      case 'black_modal':
        return 'black'
      default:
        return '#F9D549'
    }
  }};
  padding: ${({ variant }) => {
    switch (variant) {
      case 'transparent':
        return '12px 16px'
      case 'black':
        return '16px 24px'
      case 'black_submit':
        return '16px 35px'
      case 'black_set':
        return '16px 24px'
      case 'acc_menu':
        return '10px 14px'
      case 'account':
        return '8px 20px'
      case 'link_modal':
        return '16px 10px'
      default:
        return '8px 18px'
    }
  }};
  width: ${({ variant, size }) => {
    if (size === 'modal' || size === 'modal_invest') {
      return '100%'
    }
    switch (variant) {
      case 'sign_in':
        return '120px'
      case 'sign_up':
        return '120px'
      case 'account':
        return '155px'
      case 'burger':
        return '320px'
      case 'link_modal':
        return '200px'
      case 'black_submit':
        return '130px'
      default:
        return 'auto'
    }
  }};
  min-width: ${({ variant }) => {
    switch (variant) {
      case 'acc_menu':
        return '63px'
      case 'black_set':
        return '130px'
      case 'header':
        return '120px'
      default:
        return 'unset'
    }
  }};
  height: ${({ variant, size }) => {
    if (size === 'modal' || size === 'modal_invest') {
      return '60px'
    }
    switch (variant) {
      case 'header':
        return '44px'
      case 'acc_menu':
        return '32px'
      case 'sign_in':
        return '100%'
      case 'sign_up':
        return '100%'
      case 'account':
        return '100%'
      case 'burger':
        return '80px'
      default:
        return 'auto'
    }
  }};
  border-radius: ${({ variant, size }) => {
    if (size === 'modal' || size === 'modal_invest') {
      return '64px'
    }
    switch (variant) {
      case 'transparent':
        return '8px'
      case 'burger':
        return '48px'
      default:
        return '64px'
    }
  }};
  color: ${({ variant }) => {
    switch (variant) {
      case 'black':
        return 'white'
      case 'black_submit':
        return 'white'
      case 'black_set':
        return 'white'
      case 'acc_menu':
        return 'white'
      case 'black_modal':
        return 'rgba(253, 253, 253, 0.8)'
      default:
        return 'black'
    }
  }};
  border: ${({ variant }) => {
    switch (variant) {
      case 'transparent':
        return '1px solid #F3F3F3'
      case 'bordered':
        return '1px solid #cccccc'
      case 'header':
        return '1px solid #CCCCCC'
      case 'sign_in':
        return '1px solid #CCCCCC'
      case 'burger':
        return '1px solid #CCCCCC'
      case 'black_modal':
        return '1px solid black'
      default:
        return 'none'
    }
  }};
  cursor: pointer;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: ${({ variant, size }) => {
    if (size === 'modal' || size === 'modal_invest') {
      return '16px'
    }
    switch (variant) {
      case 'burger':
        return '24px'
      case 'link_modal':
        return '16px'
      default:
        return '12px'
    }
  }};
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
  &:hover {
    background: ${({ variant }) => {
      switch (variant) {
        case 'transparent':
          return 'black'
        case 'bordered':
          return 'rgba(253, 253, 253, 0.5)'
        case 'header':
          return 'black'
        case 'sign_in':
          return 'black'
        case 'black_submit':
          return 'white'
        case 'black_set':
          return 'white'
        case 'black_modal':
          return 'rgba(0, 0, 0, 0.9)'
        default:
          return '#F9D549'
      }
    }};
    color: ${({ variant }) => {
      switch (variant) {
        case 'transparent':
          return 'white'
        case 'bordered':
          return 'rgba(0, 0, 0, 0.8)'
        case 'header':
          return 'white'
        case 'sign_in':
          return 'white'
        case 'black_modal':
          return 'rgba(253, 253, 253, 0.8)'
        default:
          return 'black'
      }
    }};
    border: ${({ variant }) => {
      switch (variant) {
        case 'transparent':
          return '1px solid black'
        case 'bordered':
          return '1px solid rgba(204, 204, 204, 1)'
        case 'header':
          return '1px solid black'
        case 'black_modal':
          return '1px solid rgba(0, 0, 0, 0.9)'
        default:
          return 'none'
      }
    }};
    .chevron {
      border-top: 1px solid white;
      border-left: 1px solid white;
    }
  }
  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
    color: ${({ variant }) => {
      switch (variant) {
        case 'black_modal':
          return 'rgba(255, 255, 255, 0.5)'
        default:
          return 'rgba(0, 0, 0, 0.5)'
      }
    }};
  }
  &:disabled:hover&:after,
  &[disabled]:hover&:after {
    display: none;
  }
  &:disabled&:active,
  &[disabled]&:active {
    transform: none;
  }
  &:active {
    transform: scale(0.9);
  }
  @media (max-width: 768px) {
    padding: ${({ variant }) => {
      switch (variant) {
        case 'transparent':
          return '12px 12px'
        case 'black':
          return '16px 24px'
        case 'sign_in':
          return '0'
        case 'sign_up':
          return '0'
        default:
          return '16px 30px'
      }
    }};
    width: ${({ variant, size }) => {
      if (size === 'modal' || size === 'modal_invest') {
        return '100%'
      }
      switch (variant) {
        case 'black_submit':
          return '100%'
        case 'black_set':
          return '100%'
        case 'sign_in':
          return '80px'
        case 'sign_up':
          return '80px'
        case 'account':
          return '155px'
        case 'burger':
          return '320px'
        case 'link_modal':
          return '155px'
        default:
          return 'auto'
      }
    }};
    max-width: 400px;
    margin: ${({ variant }) =>
      variant === 'black' ||
      variant === 'black_set' ||
      variant === 'black_submit'
        ? 'auto'
        : 'unset'};
    font-size: ${({ variant, size }) => {
      if (size === 'modal') {
        return '16px'
      } else if (size === 'modal_invest') {
        return '14px'
      }
      switch (variant) {
        case 'burger':
          return '24px'
        case 'link_modal':
          return '16px'
        default:
          return '12px'
      }
    }};
    height: ${({ variant, size }) => {
      if (size === 'modal') {
        return '60px'
      } else if (size === 'modal_invest') {
        return '50px'
      }
      switch (variant) {
        case 'header':
          return '44px'
        case 'acc_menu':
          return '32px'
        case 'sign_in':
          return '100%'
        case 'sign_up':
          return '100%'
        case 'account':
          return '100%'
        case 'burger':
          return '80px'
        default:
          return 'auto'
      }
    }};
  }
`

export const Icon = styled.div``
