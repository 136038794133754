import fetch from './fetch'

export async function getAllQuestions() {
  return await fetch(`/faq/`, { method: 'GET', withSoftAuth: true })
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .then((response) => response.json())
    .catch((err) => {
      throw new Error(err)
    })
}