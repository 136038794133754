import Carousel from 'react-material-ui-carousel'
import Card from '../Card'
import '../../images/PrevIcon.svg'
import useMediaQuery from '@mui/material/useMediaQuery'
import styled from 'styled-components'

const CarouselContainer = styled.div`
  max-width: 1332px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
`

function CardsCarousel({
  projects,
  handleOpenInvestModal,
  handleOpenBuyModal,
  handleSetProject,
  handleOpenClaimModal,
}) {
  const isMobile = useMediaQuery('(max-width:985px)')

  return (
    <CarouselContainer>
      <Carousel
        showDots={true}
        autoPlay={false}
        className="carousel"
        indicators={isMobile}
        swipe={isMobile}
        keyBoardControl={true}
        fullHeightHover={false}
        navButtonsAlwaysVisible={!isMobile}
        navButtonsAlwaysInvisible={isMobile}
        NextIcon={<img src={require('../../images/NextIcon.svg').default} alt='next'/>}
        PrevIcon={<img src={require('../../images/PrevIcon.svg').default} alt='prev'/>}
        navButtonsProps={{
          style: {
            backgroundColor: 'transparent',
            borderRadius: 0,
          },
        }}
        height={!isMobile && 608}
        indicatorContainerProps={{
          style: {
            marginTop: '50px',
          },
        }}
        animation="slide"
      >
        {projects.map((data, index) => (
          <Card
            key={index}
            projectsData={data}
            variant="carousel"
            isMobile={isMobile}
            handleOpenInvestModal={handleOpenInvestModal}
            handleOpenBuyModal={handleOpenBuyModal}
            handleOpenClaimModal={handleOpenClaimModal}
            handleSetProject={handleSetProject}
          />
        ))}
      </Carousel>
    </CarouselContainer>
  )
}

export default CardsCarousel
