import React from 'react'
import { TeamContainer, WebContainer, Title, NoPartnersText } from './ProjectPartners.styled'

function ProjectPartners({ name, partners }) {
  return (
    <TeamContainer>
      <Title>{name} backers and partners</Title>
      {!partners || partners.length === 0 ? (
        <NoPartnersText>
          There is no information on backers and partners
        </NoPartnersText>
      ) : (
        <WebContainer dangerouslySetInnerHTML={{ __html: partners }} />
      )}
    </TeamContainer>
  )
}

export default ProjectPartners
