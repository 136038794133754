import { useMediaQuery } from '@mui/material'
import Button from '../Button'
import KYCWidget from '../KYCWidget'
import {
  AlertBannerContainer,
  AlertIconMessageContainer,
  Wrapper,
  AlertIconContainer,
  AlertIcon,
  AlertBannerMessage,
  AlertBannerMessageTitle,
  AlertBannerMessageText,
  AlertButtonContainer,
  Icon,
} from './AlertBanner.styled'
import { ReactComponent as MedalIcon } from '../../images/MedalIcon.svg'

const AlertBanner = ({ data, variant, handleClick }) => {
  const isMobile = useMediaQuery('(max-width:768px)')

  return (
    <AlertBannerContainer variant={variant}>
      <AlertIconMessageContainer>
        <Wrapper>
          <AlertIconContainer variant={variant}>
            {variant === 'success' ? (
              <Icon>
                <MedalIcon />
              </Icon>
            ) : (
              <AlertIcon
                src={require('../../images/AlertIcon.svg').default}
                alt="alertIcon"
              />
            )}
          </AlertIconContainer>
          {isMobile && (
            <AlertBannerMessageTitle>{data.title}</AlertBannerMessageTitle>
          )}
        </Wrapper>
        <AlertBannerMessage>
          {!isMobile && (
            <AlertBannerMessageTitle>{data.title}</AlertBannerMessageTitle>
          )}
          <AlertBannerMessageText
            variant={variant}
            dangerouslySetInnerHTML={{ __html: data.description }}
          />
        </AlertBannerMessage>
      </AlertIconMessageContainer>
      {data.actionButtonText && (
        <AlertButtonContainer>
          {data.actionButtonText === 'Submit' ? (
            <KYCWidget />
          ) : (
            <Button variant="black_set" onClick={handleClick}>
              {data.actionButtonText}
            </Button>
          )}
        </AlertButtonContainer>
      )}
    </AlertBannerContainer>
  )
}

export default AlertBanner
