import React, { useState } from 'react'
import {
  Container,
  Label,
  StyledInput,
  Asterisk,
  InputContainer,
  IconContainer,
  Help,
} from './Input.styled'
import { Visibility, VisibilityOff } from '@mui/icons-material'

function Input(
  {
    variant,
    type,
    placeholder,
    label,
    name,
    required,
    status,
    help,
    value,
    onChange,
    onBlur,
    pattern,
    title,
  },
  ref,
) {
  const [visible, setVisible] = useState(false)

  const handleClick = () => {
    setVisible(!visible)
  }

  return (
    <Container variant={variant}>
      <Label htmlFor={name}>
        {label}
        {required && <Asterisk> *</Asterisk>}
      </Label>
      <InputContainer status={status} id="input">
        <StyledInput
          ref={ref}
          name={name}
          type={(type === 'password') & visible ? 'text' : type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          pattern={pattern}
          title={title}
          autoComplete="off"
        />
        {type === 'password' && (
          <IconContainer onClick={handleClick} type="button">
            {visible ? (
              <Visibility color="black" />
            ) : (
              <VisibilityOff color="black" />
            )}
          </IconContainer>
        )}
      </InputContainer>
      {help && <Help>{help}</Help>}
    </Container>
  )
}

export default React.forwardRef(Input)
