import styled from 'styled-components'

export const WebContainer = styled.div`
  padding: 40px;
  line-height: 200%;
  letter-spacing: 0.03em;

  h1,
  h2,
  h3,
  h4,
  p {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: left;
    :last-child {
      margin-bottom: 0;
    }
  }
  h2 {
    font-weight: 500;
    text-align: justify;
  }
  h1 {
    line-height: 100%;
    text-align: center;
  }
  ul,
  ol {
    margin: 40px 0;
  }
  li {
    text-align: left;
    font-size: 16px;
  }
  h1 {
    font-size: 32px;
  }

  a {
    word-break: break-word;
    text-decoration: none;
    * {
      text-decoration: none;
    }
  }
  @media (max-width: 900px) {
    img {
      width: 100% !important;
      height: unset !important;
    }
  }
`

export const NoMetricsText = styled.p`
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.03em;
  line-height: 17px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  text-align: left;
`

export const MetricsContainer = styled.div`
  margin: 22px 20px;
`

export const Title = styled.p`
  font-family: 'Roboto Mono', monospace;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin: 40px 0 32px;
  text-align: left;
`
