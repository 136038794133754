import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { GlobalFonts } from './fonts'
import { BrowserRouter } from 'react-router-dom'
import ConfigProvider from './contexts/ConfigContext'
import ScrollToTop from './utils/scrollToTop'
import TagManager from 'react-gtm-module'

const root = ReactDOM.createRoot(document.getElementById('root'))

const tagManagerArgs = {
  gtmId: 'G-3EEZHT3XPT',
}

TagManager.initialize(tagManagerArgs)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <GlobalFonts />
      <ConfigProvider>
        <App />
      </ConfigProvider>
    </BrowserRouter>
  </React.StrictMode>,
)
