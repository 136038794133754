import React from 'react'
import { Menu, Close } from '@mui/icons-material'
import { Button } from './MenuButton.styled'

function MenuButton({ isOpen, handleClose, handleOpen }) {
  return (
    <Button onClick={isOpen ? handleClose : handleOpen}>
      {isOpen ? <Close color='black' /> : <Menu color='black' />}
    </Button>
  )
}

export default MenuButton
