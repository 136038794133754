import {
  HelpPageParagraph,
  HelpPageTitle,
  Ul,
} from '../../pages/FaqPage/FaqPage.styled'


function FaqQuestion({
  question,
  answer
}) {
  return (
    <Ul>
      <HelpPageTitle dangerouslySetInnerHTML={{ __html: question }} />
      <HelpPageParagraph dangerouslySetInnerHTML={{ __html: answer }} />
    </Ul>
  )
}

export default FaqQuestion
