import styled from 'styled-components'

export const Balance = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: ${({ variant }) => {
    switch (variant) {
      case 'menu':
        return '24px'
      default:
        return '12px'
    }
  }};
  line-height: 100%;
  font-weight: 500;
  gap: 12px;
  margin-right: ${({ variant }) => {
    switch (variant) {
      case 'header':
        return '16px'
      default:
        return '0'
    }
  }};

  height: ${({ variant }) => {
    switch (variant) {
      case 'menu':
        return '50px'
      default:
        return 'unset'
    }
  }};

  @media (max-width: 1220px) {
    display: ${({ variant }) => {
      switch (variant) {
        case 'header':
          return 'none'
        default:
          return 'flex'
      }
    }};
  }
`
