import React from 'react'
import Banner from '../Banner'
import Button from '../Button'
import { Container, StakeBannerLink } from './StakeBanner.styled'
import { StakeBannerInfo } from '../../constants'

function StakeBanner() {
  const button = (
    <StakeBannerLink
      href="https://staking.step.app/"
      target="_blank"
      rel="noreferrer"
    >
      <Button variant="black">{StakeBannerInfo.actionButtonText}</Button>
    </StakeBannerLink>
  )

  return (
    <Container>
      <Banner
        title={StakeBannerInfo.title}
        description={StakeBannerInfo.description}
        buttons={button}
        variant="stake"
      />
    </Container>
  )
}

export default StakeBanner
