import styled from 'styled-components'

export const StyledTag = styled.div`
  background: ${({ variant }) => {
    return variant === 'carousel' ? '#FDFDFD' : '#EAEBEB'
  }};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: auto;
  white-space: nowrap;

  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: black;
`
