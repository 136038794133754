import { useState, useEffect } from 'react'
import { MainContainer } from './Main.styled'
import { getAllProjects } from '../../api/'
import Spinner from '../../components/Spinner'
import LaunchpadBanner from '../../components/LaunchpadBanner'
import Statistics from '../../components/Statistics'
import StakeBanner from '../../components/StakeBanner'
import AboutSection from '../../components/AboutSection'
import CardsCarousel from '../../components/CardsCarousel'

const Main = ({
  claimModalOpen,
  investModalOpen,
  buyModalOpen,
  setCarouselProject,
  forcedAfterApply,
}) => {
  const [projects, setProjects] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  const handleSetProject = (project) => {
    setCarouselProject(project)
  }

  useEffect(() => {
    setIsFetching(true)
    getAllProjects()
      .then((projectsList) => {
        setProjects(projectsList)
        setIsFetching(false)
      })
      .catch((e) => {
        setIsFetching(false)
        console.log(e)
      })
  }, [forcedAfterApply])

  return (
    <MainContainer>
      <LaunchpadBanner />
      <Statistics />
      {isFetching ? (
        <Spinner />
      ) : (
        projects &&
        projects.length > 0 && (
          <CardsCarousel
            projects={projects}
            handleOpenInvestModal={investModalOpen}
            handleOpenBuyModal={buyModalOpen}
            handleSetProject={handleSetProject}
            handleOpenClaimModal={claimModalOpen}
          />
        )
      )}
      <StakeBanner />
      <AboutSection />
    </MainContainer>
  )
}

export default Main
