import React, { useContext, useState, useEffect } from 'react'

import {
  Modal,
  ModalContainer,
  Title,
  ButtonContainer,
  Text,
  RadioContainer,
  ErrorText,
  Banner,
  AccentText,
  ModalContainerHideScrollWrapper,
} from './Modal.styled'
import Button from '../Button'

import RadioButtons from '../RadioButtons'
import { UserAuthContext, ConfigContext } from '../../contexts'
import Spinner from '../Spinner'
import { postApplicationForm } from '../../api'

function InvestModal({
  isOpen,
  handleClose,
  modalRef,
  amountChoices,
  projectName,
  forceUpdateAfterApply,
}) {
  const { user } = useContext(UserAuthContext)
  const { config } = useContext(ConfigContext)
  const [loading, setLoading] = useState(false)
  const [chosen, setChosen] = useState()
  const [error, setError] = useState('')

  function clearErrors() {
    setError('')
  }

  useEffect(() => {
    clearErrors()
  }, [isOpen])

  const handleChoose = (event) => {
    if (event.target.name !== chosen) {
      setChosen(event.target.name)
    } else {
      setChosen(null)
    }
  }

  const handleModalClose = () => {
    handleClose()
    clearErrors()
  }

  function sendApplication() {
    setLoading(true)
    clearErrors()
    if (chosen) {
      postApplicationForm(chosen, projectName)
        .then(() => {
          setLoading(false)
          handleModalClose()
          forceUpdateAfterApply()
        })
        .catch((err) => {
          console.log(err.message)
          setLoading(false)
          setError(err.message)
        })
    }
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalContainerHideScrollWrapper>
        <ModalContainer ref={modalRef} variant="invest">
          <Title variant="invest">Soft commitment</Title>
          <Text variant="invest">
            Choose the volume of planned purchase. You can only redeem the
            entire position after the sale gets started.
          </Text>
          {user.balance < config.min_stfitfi_balance ? (
            <Banner>
              <Text variant="invest">
                SOFT COMMITMENTS ARE OPEN FOR EVERYONE. BUT YOU WILL NEED TO
                STAKE AT LEAST{' '}
                <AccentText>{config.min_stfitfi_balance} STFITFI</AccentText>{' '}
                BEFORE DISTRIBUTION START.
              </Text>
              <Button
                size="modal_invest"
                variant="black_modal"
                onClick={() => window.open('https://staking.step.app/')}
              >
                Stake
              </Button>
            </Banner>
          ) : null}
          <RadioContainer>
            <RadioButtons
              chosen={chosen}
              options={amountChoices}
              onClick={handleChoose}
              currency="$"
            />
          </RadioContainer>
          <ButtonContainer>
            <Button
              size="modal_invest"
              type="submit"
              onClick={sendApplication}
              disabled={!chosen}
            >
              {loading ? <Spinner size="16px" color="white" /> : 'Apply'}
            </Button>
            <Button
              size="modal_invest"
              onClick={handleClose}
              variant="bordered"
            >
              Close
            </Button>
            {error ? <ErrorText variant="invest">{error}</ErrorText> : null}
          </ButtonContainer>
        </ModalContainer>
      </ModalContainerHideScrollWrapper>
    </Modal>
  )
}

export default React.memo(InvestModal)
