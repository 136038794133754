import React, { useContext } from 'react'
import { Item, Divider, Key, Value, Icon, LogOut, ChangePassword } from './Menu.styled'
import Button from '../Button'
import { shortenAddress, shortenEmail } from '../../utils/shortenAddress'
import Menu from '.'
import { ReactComponent as Copy } from '../../images/Copy.svg'
import Tooltip from '@mui/material/Tooltip'
import { logoutUser } from '../../api'
import {
  ConfigContext,
  ConnectionContext,
  UserAuthContext,
} from '../../contexts'
import useLinkedAddress from '../../hooks/useLinkedAddress'
import NotificationPopUp from '../NotificationPopUp'
import {
  AdddresLinkedNotification,
  UniqueAdddresErrorNotification,
} from '../../constants'

function AccountMenu({
  handleClick,
  handleOpenInstallMetamask,
  linkAddressCallback,
  openModal,
}) {
  const { user, logOut } = useContext(UserAuthContext)
  const { userAddress, connectWallet } = useContext(ConnectionContext)
  const { config } = useContext(ConfigContext)

  const { linked, uniqueError, handleSet } = useLinkedAddress({
    config,
    handleOpenInstallMetamask,
    linkAddressCallback,
  })

  const handleLogout = () => {
    logoutUser(JSON.parse(localStorage.getItem('authTokens')).refresh).then(
      () => {
        logOut()
        handleClick()
      },
    )
  }

  const emailItem = (
    <Item>
      <Key>Email</Key>
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: 'black',
              fontSize: '14px',
              fontFamily: '"Roboto Mon", monospace',
              marginRight: '20px',
              lineHeight: '100%',
              textTransform: 'uppercase',
              letterSpacing: '0.05em',
            },
          },
        }}
        title={user?.email}
      >
        <Value>{shortenEmail(user?.email, 20)}</Value>
      </Tooltip>
    </Item>
  )

  const kycItem = (
    <Item>
      <Key>KYC</Key>
      <Value>{user?.kycStatus?.replace('_', ' ')}</Value>
    </Item>
  )

  const copyAddress = (address) => {
    navigator.clipboard?.writeText(address)
  }

  async function setAddress() {
    await handleSet(user.id, userAddress)
  }

  const ethItem = (
    <Item>
      <Key>Step address</Key>
      {user.ethAddress ? (
        <Value>
          {shortenAddress(user.ethAddress)}
          <Icon onClick={() => copyAddress(user.ethAddress)}>
            <Copy />
          </Icon>
        </Value>
      ) : userAddress ? (
        <Value>
          {shortenAddress(userAddress)}
          <Icon onClick={() => copyAddress(userAddress)} />
          {!linked ? (
            <Button variant="acc_menu" onClick={setAddress}>
              Set
            </Button>
          ) : (
            <Copy />
          )}
        </Value>
      ) : !window.ethereum ? (
        <Button variant="acc_menu" onClick={handleOpenInstallMetamask}>
          Connect wallet
        </Button>
      ) : (
        <Button variant="acc_menu" onClick={connectWallet}>
          Connect wallet
        </Button>
      )}
    </Item>
  )

  const balanceItem = (
    <Item>
      <Key>balance</Key>
      <Value>{user.balance} stFITFI</Value>
    </Item>
  )

  return (
    <>
      <Menu handleClick={handleClick}>
        {emailItem}
        {kycItem}
        {ethItem}
        {user.ethAddress ? balanceItem : null}
        <Divider />
        <Item>
          <Key>Support</Key>
          <Value>{config.support_mail}</Value>
        </Item>
        <Divider />
        <Item>
          <ChangePassword
            onClick={() => {
              handleClick()
              openModal()
            }}
          >
            change password
          </ChangePassword>
        </Item>

        <Divider />
        <Item>
          <LogOut onClick={handleLogout}>Log out</LogOut>
        </Item>
      </Menu>
      <NotificationPopUp isOpen={linked} message={AdddresLinkedNotification} />

      <NotificationPopUp
        isOpen={uniqueError}
        message={UniqueAdddresErrorNotification}
        isError={true}
      />
    </>
  )
}

export default AccountMenu
