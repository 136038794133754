import styled from 'styled-components'

export const Title = styled.div`
  font-family: 'Roboto Mono', monospace;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin-bottom: 10px;
  @media (max-width: 769px) {
    text-align: left;
  }
`

export const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  box-sizing: border-box;
  padding: 20px 0;
  @media (max-width: 1200px) {
    margin: 0 20px;
    gap: 24px;
  }
`

export const CardText = styled.p`
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  text-align: center;
  color: black;
  @media (max-width: 769px) {
    font-size: 12px;
    line-height: 12px;
  }
`

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  flex-grow: 1 1 300px;
  justify-content: center;
`

export const Logo = styled.img`
  max-width: 278px;
  max-height: 160px;
  @media (max-width: 769px) {
    max-width: 152px;
    max-height: 100px;
  }
`

export const NoPartnersText = styled.p`
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.03em;
  line-height: 17px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
`

export const WebContainer = styled.div`
  padding: 40px;
  line-height: 200%;
  letter-spacing: 0.03em;

  h1,
  h2,
  h3,
  h4,
  p {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: left;
    :last-child {
      margin-bottom: 0;
    }
  }
  h2 {
    font-weight: 500;
    text-align: justify;
  }
  h1 {
    line-height: 100%;
    text-align: center;
  }
  ul,
  ol {
    margin: 40px 0;
  }
  li {
    text-align: left;
    font-size: 16px;
  }
  h1 {
    font-size: 32px;
  }

  a {
    word-break: break-word;
    text-decoration: none;
    * {
      text-decoration: none;
    }
  }
  @media (max-width: 900px) {
    img {
      width: 100% !important;
      height: unset !important;
    }
  }
`
