import {
  Modal,
  ModalContainer,
  Title,
  Text,
  Link,
  ButtonContainer,
} from './Modal.styled'
import Button from '../Button'

export default function InstallMetamaskModal({ isOpen, onClose, modalRef }) {
  const dappUrl = window.location.href.split('//')[1]
  const metamaskAppDeepLink = 'https://metamask.app.link/dapp/' + dappUrl

  return (
    <Modal isOpen={isOpen} zIndex={200}>
      <ModalContainer ref={modalRef}>
        <Title>Important</Title>
        <Text>
          TO CONNECT ETH ADDRESS, AN ETHEREUM WALLET MUST BE INSTALLED IN YOUR
          BROWSER.
        </Text>
        <Text>
          <Link href={metamaskAppDeepLink} target="_blank" rel="noreferrer">
            METAMASK
          </Link>{' '}
          SUITS MOST PLATFORMS.
        </Text>
        <ButtonContainer>
          <Button
            size="modal"
            variant="black_modal"
            type="submit"
            onClick={onClose}
          >
            Ok
          </Button>
        </ButtonContainer>
      </ModalContainer>
    </Modal>
  )
}
