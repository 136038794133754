import React, { useContext } from 'react'
import { UserAuthContext, ConfigContext } from '../../contexts'
import { useNavigate, useLocation } from 'react-router-dom'
import { getUserStatus } from '../../utils/getUserStatus'
import {
  PROJECT_STATUS,
  USER_STATUS,
  ACTION_BUTTON_TEXT,
} from '../../constants'
import { StyledButton } from './ActionButton.styled'
import { ethers } from 'ethers'
import ClaimButton from './ClaimButton'

function ActionButton({
  location: propsLocation,
  variant,
  status,
  applied,
  handleOpenInvestModal,
  handleOpenBuyModal,
  handleOpenClaimModal,
  amount,
  allocation,
}) {
  const navigate = useNavigate()
  const location = useLocation()
  const { isLogined } = useContext(UserAuthContext)
  const { config } = useContext(ConfigContext)

  const needAmount =
    status === PROJECT_STATUS.apply || status === PROJECT_STATUS.lottery

  const userStatus = getUserStatus({ isLogined, applied, allocation })

  const text = ACTION_BUTTON_TEXT[userStatus][status]

  if (status === PROJECT_STATUS.vesting) {
    return (
      <ClaimButton
        location={propsLocation}
        variant={variant}
        text={text}
        handleOpenClaimModal={handleOpenClaimModal}
      />
    )
  }

  switch (userStatus) {
    case USER_STATUS.unauthorized:
      return (
        text && (
          <StyledButton
            location={propsLocation}
            variant={variant}
            onClick={() =>
              navigate(
                `/signin${
                  location.pathname ? `?return_to=${location.pathname}` : null
                }`,
              )
            }
          >
            {text}
          </StyledButton>
        )
      )
    case USER_STATUS.authorized:
      if (status === PROJECT_STATUS.apply) {
        return (
          text && (
            <StyledButton
              location={propsLocation}
              variant={variant}
              onClick={handleOpenInvestModal}
            >
              {text}
            </StyledButton>
          )
        )
      }
      return (
        text && (
          <StyledButton variant={variant} location={propsLocation} disabled>
            {text}
          </StyledButton>
        )
      )
    case USER_STATUS.allocated:
      if (status === PROJECT_STATUS.sale) {
        return (
          text && (
            <StyledButton
              location={propsLocation}
              variant={variant}
              onClick={handleOpenBuyModal}
            >
              {text}{' '}
              {allocation?.max_amount && config.network?.usdc_decimals
                ? ` up to $${ethers.utils.formatUnits(
                    allocation?.max_amount,
                    config.network?.usdc_decimals,
                  )}`
                : null}
            </StyledButton>
          )
        )
      }
      return (
        text && (
          <StyledButton variant={variant} location={propsLocation} disabled>
            {text}
          </StyledButton>
        )
      )
    case USER_STATUS.applied:
      if (status === PROJECT_STATUS.sale && !allocation) {
        return null
      }
      return (
        text && (
          <StyledButton location={propsLocation} variant={variant} disabled>
            {text} {needAmount && amount ? `($${amount})` : null}
          </StyledButton>
        )
      )
    default:
      return null
  }
}

export default ActionButton
