import styled, { css } from 'styled-components'

export const Helper = styled.div`
  font-family: 'Roboto Mono', monospace;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  color: black;
  margin-bottom: ${({ location }) => {
    switch (location) {
      case 'project':
        return '12px'
      default:
        return '0'
    }
  }};
  align-self: ${({ column, location }) =>
    column || location === 'project' ? 'unset' : 'center'};
  @media (max-width: 768px) {
    align-self: unset;
  }

  ${({ variant }) => {
    if (variant === 'banner') {
      return css`
        background: #f3f3f3;
        border-radius: 24px;
        padding: 24px;

        @media (max-width: 768px) {
          padding: 16px;
        }
      `
    }
  }}
`

export const Link = styled.a`
  color: #4f79e4;
`
