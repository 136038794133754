import {
  Container,
  Title,
  Description,
  BackButtonLink,
} from './NotFoundModal.styled'

function NotFoundPage() {
  return (
    <Container>
      <BackButtonLink to="/">Back</BackButtonLink>
      <Title>404</Title>
      <Description>Page not found</Description>
    </Container>
  )
}

export default NotFoundPage
