import React, { useEffect, useContext } from 'react'
import PasswordInputs from '../PasswordInputs'
import {
  ButtonContainer,
  Modal,
  ModalContainer,
  Title,
  ChangePasswordForm,
} from './Modal.styled'
import { useForm } from 'react-hook-form'
import Input from '../Input'
import Button from '../Button'
import { UserAuthContext } from '../../contexts'
import { Help } from '../Input/Input.styled'

const ChangePasswordModal = ({ isOpen, handleClose }, ref) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    clearErrors,
    setError,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: {
      oldPassword: '',
      password: '',
      repeat: '',
    },
    mode: 'onChange',
  })
  const { changePasswordHandler, errorText, setErrorText } =
    useContext(UserAuthContext)

  useEffect(() => {
    const subscription = watch(() => {
      if (errorText) {
        setErrorText('')
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, errorText]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      errors.password?.message === 'please use a different password' &&
      watch('password') !== watch('oldPassword')
    ) {
      clearErrors('password')
    }
  }, [watch('oldPassword')]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    reset()
    setErrorText('')
  }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => handleClose()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Modal isOpen={isOpen}>
      <ModalContainer ref={ref}>
        <Title>change your password</Title>
        <ChangePasswordForm
          noValidate
          onSubmit={handleSubmit(changePasswordHandler)}
        >
          <Input
            variant="change_password"
            type="password"
            label="old password"
            placeholder="Enter old password"
            required
            help={errors.oldPassword?.message}
            status={errors.oldPassword?.message}
            title={'Please enter your old password'}
            {...register('oldPassword', {
              required: 'This field should not be empty',
              validate: (val) => {
                if (watch('password') === val) {
                  setError('password', {
                    type: 'validate',
                    message: 'please use a different password',
                  })
                }
              },
            })}
          />
          <PasswordInputs
            variant="change_password"
            errors={errors}
            register={register}
            watch={watch}
            condition={true}
            clearError={clearErrors}
            setError={setError}
          />
          {errorText && <Help style={{ position: 'unset' }}>{errorText}</Help>}
          <ButtonContainer>
            <Button size="modal" disabled={!isDirty || !isValid} type="submit">
              change password
            </Button>
            <Button
              variant="bordered"
              size="modal"
              type="reset"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </ButtonContainer>
        </ChangePasswordForm>
      </ModalContainer>
    </Modal>
  )
}

export default React.memo(React.forwardRef(ChangePasswordModal))
