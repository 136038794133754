import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.div`
  min-height: calc(100vh - 80px - 144px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  position: relative;

  @media (max-width: 768px) {
    gap: 20px;
  }
`

export const Title = styled.h1`
  font-size: 120px;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 100px;
  }
`

export const Description = styled.h2`
  font-size: 40px;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 25px;
  }
`

export const BackButtonLink = styled(Link)`
  position: absolute;
  top: 130px;
  left: 210px;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: black;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 820px) {
    top: 50px;
    left: 50px;
  }
`
