import {
  HeaderContainer,
  LogoContainer,
  ButtonContainer,
  NetworkContainer,
  MenuContainer,
  HeaderLogo,
  HeaderLogoLink,
  HeaderLink,
  RightContainer,
} from './Header.styled'
import Button from '../Button'
import ConnectWallet from '../ConnectWallet'
import MenuButton from '../MenuButton'
import AuthButtons from '../AuthButtons'
import StakedFitfi from '../StakedFitfi'

function Header({
  handleMenuClose,
  handleMenuOpen,
  menuOpen,
  handleOpenInstallMetamask,
  linkAddressCallback,
}) {
  return (
    <HeaderContainer>
      <LogoContainer>
        <HeaderLogoLink to="/">
          <HeaderLogo
            src={require('../../images/StepLaunch.svg').default}
            alt="logo"
          />
        </HeaderLogoLink>
      </LogoContainer>
      <ButtonContainer>
        <HeaderLink
          href="https://coinmarketcap.com/currencies/step-app/markets/"
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="header">Buy</Button>
        </HeaderLink>
        <HeaderLink
          href="https://app.step.exchange/#/swap"
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="header">Swap</Button>
        </HeaderLink>
        <HeaderLink
          href="https://staking.step.app/"
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="header">Stake</Button>
        </HeaderLink>
      </ButtonContainer>
      <RightContainer>
        <StakedFitfi variant="header" />
        <NetworkContainer>
          <ConnectWallet
            variant="header"
            handleOpenInstallMetamask={handleOpenInstallMetamask}
          />
        </NetworkContainer>
        <AuthButtons
          handleOpenInstallMetamask={handleOpenInstallMetamask}
          linkAddressCallback={linkAddressCallback}
        />
        <MenuContainer>
          <MenuButton
            handleClose={handleMenuClose}
            handleOpen={handleMenuOpen}
            isOpen={menuOpen}
          />
        </MenuContainer>
      </RightContainer>
    </HeaderContainer>
  )
}

export default Header
