import React from 'react'
import { RadioGroup, RadioButton } from './RadioButtons.styled'

function RadioButtons({ chosen, options, onClick, currency }) {
  const buttons = options?.map((option) => (
    <RadioButton
      key={String(option)}
      name={String(option)}
      onClick={onClick}
      chosen={chosen === String(option)}
    >
      {`${currency}${option} `}
    </RadioButton>
  ))

  return <RadioGroup>{buttons}</RadioGroup>
}

export default RadioButtons
