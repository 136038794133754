import { useState, useEffect } from 'react'
import { getAllQuestions } from '../../api/'
import {
  HelpPageContainer,
  HelpPageMainTitle,
} from './FaqPage.styled'
import FaqQuestion from '../../components/FaqQuestion'

const FaqPage = ({
  forcedAfterApply,
}) => {

  const [questions, setQuestions] = useState([])

  useEffect(() => {
    getAllQuestions()
      .then((questionsList) => {
        setQuestions(questionsList)
      })
      .catch((e) => {
        console.log(e)
      })
  }, [forcedAfterApply])

  return (
    <HelpPageContainer>
    <HelpPageMainTitle>FAQ</HelpPageMainTitle>
    {questions.map((data, index) => (
      <FaqQuestion key={index} question={data.question} answer={data.answer} />
    ))}
    </HelpPageContainer>
  )
}

export default FaqPage
