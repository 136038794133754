import styled from 'styled-components'

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ size }) => {
    if (size) {
      return '0px auto'
    } else {
      return '40px auto'
    }
  }};
`
