import CircularProgress from '@mui/material/CircularProgress'
import { SpinnerWrapper } from './Spinner.styled'

export default function Spinner({ size, color }) {
  return (
    <SpinnerWrapper size={size}>
      <CircularProgress
        size={size || '48px'}
        sx={{ color: color || `black` }}
      />
    </SpinnerWrapper>
  )
}
