import { useEffect, useContext, useState } from 'react'
import Button from '../Button'
import { UserAuthContext, ConfigContext } from '../../contexts'
import NotificationPopUp from '../NotificationPopUp'

function KYCWidget() {
  const { user } = useContext(UserAuthContext)
  const { config } = useContext(ConfigContext)
  const [message, setMessage] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (user.id && config.blockpass_client_id) {
      const blockpass = new window.BlockpassKYCConnect(
        config.blockpass_client_id,
        {
          refId: user.ethAddress,
          email: user.email,
        },
      )
      blockpass.startKYCConnect()
      blockpass.on('KYCConnectSuccess', () => {
        setMessage('Your information has been submitted successfully.')
        setIsOpen(true)
      })
      blockpass.on('KYCConnectCancel', () => {
        setMessage('Your KYC submission was aborted. Please try again.')
        setIsOpen(true)
      })
    }
  }, [user.id, user.email, user.ethAddress, config])

  return (
    <>
      <Button
        id="blockpass-kyc-connect"
        variant="black_submit"
        onClick={() => setIsOpen(false)}
      >
        Submit
      </Button>
      <NotificationPopUp isOpen={isOpen} message={message} />
    </>
  )
}

export default KYCWidget
