import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const HeaderContainer = styled.div`
  padding: 16px 48px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 80px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 2;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;

  @media (max-width: 768px) {
    height: 74px;
    padding: 0 20px;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-right: auto;

  @media (max-width: 1220px) {
    display: none;
  }
`

export const NetworkContainer = styled.div`
  display: flex;
  gap: 18px;
  align-items: center;

  @media (max-width: 1220px) {
    display: none;
  }
`

export const MenuContainer = styled.div`
  display: none;

  @media (max-width: 1220px) {
    display: flex;
    margin-left: 15px;
  }

  @media (max-width: 768px) {
    margin-left: 5px;
  }
`

export const HeaderLogo = styled.img`
  width: 200px !important;
  cursor: pointer;
  margin-right: 48px;
  @media (max-width: 768px) {
    width: 108px !important;
    margin-right: 0;
  }
`

export const HeaderLogoLink = styled(Link)`
  display: flex;
  align-items: center;
`

export const HeaderLink = styled.a`
  text-decoration: none;
`

export const RightContainer = styled.div`
  display: flex;
  margin-left: auto;
  gap: 8px;
`
