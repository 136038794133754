import React from 'react'
import { Table, Th, Tr } from './ProjectInfo.styled'
import { getFormattedUTCDateFromString } from '../../utils/formatDate'

function ProjectInfo({ startDate, endDate }) {
  const info = {
    'Registration opens:': getFormattedUTCDateFromString(startDate),
    'Registration closes:': getFormattedUTCDateFromString(endDate),
  }

  const rows = Object.entries(info).map(([key, value]) => (
    <Tr key={key}>
      <Th scope="row">{key}</Th>
      <td>{value}</td>
    </Tr>
  ))

  return (
    <Table>
      <tbody>{rows}</tbody>
    </Table>
  )
}

export default ProjectInfo
