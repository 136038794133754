import fetch from './fetch'

export async function registerUser({ email, password }) {
  return await fetch(`/auth/register/`, {
    method: 'POST',
    body: JSON.stringify({
      email,
      password,
    }),
  })
    .then(async (response) => {
      if (response.ok) {
        return response
      }
      const err = await response.json()
      throw new Error(JSON.stringify(err.email || err.password))
    })
    .catch((err) => {
      throw new Error(err)
    })
}

export async function resendActivationEmail({ email }) {
  return await fetch('/auth/resend-activation-email/', {
    method: 'POST',
    body: JSON.stringify({ email }),
  })
    .then(async (response) => {
      if (response.ok) {
        return response
      }
      const err = await response.json()
      throw new Error(JSON.stringify(err.email || err))
    })
    .catch((err) => {
      throw new Error(err)
    })
}

export async function loginUser({ email, password }) {
  return await fetch(`/auth/token/`, {
    method: 'POST',
    body: JSON.stringify({
      email,
      password,
    }),
  })
    .then(async (response) => {
      if (response.ok) {
        return response
      }
      const err = await response.json()
      throw new Error(JSON.stringify(err.detail))
    })
    .then((response) => response.json())
    .catch((err) => {
      throw new Error(err)
    })
}

export async function refreshToken(refresh) {
  return await fetch(`/auth/token/refresh/`, {
    method: 'POST',
    body: JSON.stringify({
      refresh,
    }),
  })
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .then((response) => response.json())
    .then((access) => {
      localStorage.setItem('authTokens', JSON.stringify({ refresh, ...access }))
      return access
    })
    .catch((err) => {
      throw new Error(err)
    })
}

export async function logoutUser(refresh) {
  return await fetch(`/auth/logout/`, {
    method: 'POST',
    withAuth: true,
    body: JSON.stringify({
      refresh,
    }),
  })
    .then((response) => {
      if (response.ok) {
        return response
      }
      throw new Error(`Error: ${response.status}`)
    })
    .catch((err) => {
      throw new Error(err)
    })
}
