import React from 'react'
import Banner from '../Banner'
import Button from '../Button'
import { LaunchpadBannerInfo } from '../../constants'
import styled from 'styled-components'

const LaunchpadLink = styled.a`
  text-decoration: none;
  @media (max-width: 768px) {
    width: 100%;
  }
`
function LaunchpadBanner() {
  const button = (
    <LaunchpadLink
      href="https://forms.gle/iX1P6T1GkTgwWksg8"
      target="_blank"
      rel="noreferrer"
    >
      <Button variant="black" key="explore">
        {LaunchpadBannerInfo.actionButtonText}
      </Button>
    </LaunchpadLink>
  )

  return (
    <Banner
      title={LaunchpadBannerInfo.title}
      variant="launchpad"
      description={LaunchpadBannerInfo.description}
      buttons={button}
    />
  )
}

export default LaunchpadBanner
