import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const FooterContainer = styled.div`
  background: rgb(243, 243, 243);
  backdrop-filter: blur(16px);
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 40px 0;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 45px auto 0;
`

export const FooterLogo = styled.img`
  width: 200px;
  @media (max-width: 768px) {
    margin-left: 20px;
  }
`

export const FooterLinkContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  align-items: center;
  @media (max-width: 812px) {
    flex-direction: column;
    gap: 15px;
    text-align: center;
    margin-top: 43px;
  }
`

export const FooterLink = styled(Link)`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: black;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const Container = styled.div`
  margin: 0 auto;
  display: flex;
  width: 1350px;
  height: 100%;
  justify-content: space-between;
  @media (max-width: 1440px) {
    width: 100%;
    padding: 0 48px;
  }
  @media (max-width: 812px) {
    flex-direction: column;
    align-items: center;
  }
`
