import {
  HelpPageContainer,
  HelpPageMainTitle,
  HelpPageParagraph,
  HelpPageTitle,
  Ul,
} from './HelpPage.styled'

const HelpPage = () => {
  return (
    <HelpPageContainer>
      <HelpPageMainTitle>
        Welcome to Step Ecosystem Launchpad: Stake $FITFI to win Early Token
        Allocations
      </HelpPageMainTitle>
      <HelpPageTitle>
        To participate in upcoming Step Ecosystem projects please follow the
        steps below:
      </HelpPageTitle>
      <HelpPageTitle>Step 1</HelpPageTitle>
      <Ul>
        <li>
          Sign-Up on{' '}
          <a href="https://steplaunch.org/" target="_blank" rel="noreferrer">
            https://steplaunch.org
          </a>
          .
        </li>
        <li>
          Complete KYC, submit the form and pass the KYC to be eligible to
          participate.
        </li>
        <li>Connect your wallet.</li>
        <li>Stake the amount of $FITFI indicated to participate.</li>
        <li>
          Specify a preliminary amount that you are willing to participate in
          the IDO with. REMINDER: there will be a maximum cap.
        </li>
      </Ul>
      <HelpPageTitle>Step 2</HelpPageTitle>
      <HelpPageParagraph>
        Take a few moments to double check that you have completed the
        registration and applied for the IDO using the steps outlined above!
      </HelpPageParagraph>
      <HelpPageParagraph>
        Prepare USDC/USDT on the Step Network before the deposit stage of a project.
      </HelpPageParagraph>

      <HelpPageParagraph>
        You can transfer your assets via{' '}
        <a
          href="https://app.multichain.org/#/router"
          target="_blank"
          rel="noreferrer"
        >
          Multichain
        </a>
        .
      </HelpPageParagraph>
      <HelpPageParagraph>
        If you need some help transferring your assets via Multichain check out
        this video -{' '}
        <a
          href="https://www.youtube.com/watch?v=6Y6SlTeo798"
          target="_blank"
          rel="noreferrer"
        >
          https://www.youtube.com/watch?v=6Y6SlTeo798
        </a>
        .
      </HelpPageParagraph>
      <HelpPageTitle>Step 3</HelpPageTitle>
      <HelpPageParagraph>
        Login to Step Launchpad and connect your external wallet (e.g. Metamask)
        to access the Public Sale of a project.
      </HelpPageParagraph>
      <HelpPageTitle>Step 4</HelpPageTitle>
      <HelpPageParagraph>
        When the registration period opens, indicate the exact amount you want
        to contribute, we recommend you take a look at the project’s details
        page first.
      </HelpPageParagraph>
      <HelpPageTitle>Reminders</HelpPageTitle>
      <Ul>
        <li>Participation will be on a first come first serve basis.</li>
        <li>
          There will be a minimum and maximum contribution per wallet address.
        </li>
        <li>
          You can only participate in each project once, so make sure that you
          have the total amount that you want to allocate in your wallet before
          you complete this first transaction.
        </li>
      </Ul>
    </HelpPageContainer>
  )
}

export default HelpPage
