import React, { useContext } from 'react'
import {
  ActionDetailsButton,
  CardActionsComponent,
  CardLink,
  UpcomingDescription,
  ActionsContainer,
  ActionButtonsContainer,
} from './Card.styled'
import { getFormattedUTCDateFromString } from '../../utils/formatDate'
import ActionButton from '../ActionButton'
import { getProjectByName } from '../../api/'
import ActionHelper from '../../components/ActionHelper'
import { PROJECT_STATUS, USER_STATUS } from '../../constants'
import { getUserStatus } from '../../utils/getUserStatus'
import { UserAuthContext } from '../../contexts/UserAuthContext'

const CardActions = ({
  projectName,
  status,
  variant,
  startDate,
  handleOpenInvestModal,
  handleOpenBuyModal,
  handleOpenClaimModal,
  handleSetProject,
  applied,
  amount,
  allocation,
}) => {
  const { isLogined } = useContext(UserAuthContext)

  const userStatus = getUserStatus({ isLogined, applied, allocation })

  const handleBuy = () => {
    getProjectByName(projectName)
      .then((project) => {
        handleSetProject(project)
        handleOpenBuyModal()
      })
      .catch((e) => console.log(e))
  }

  const handleApply = () => {
    getProjectByName(projectName)
      .then((project) => {
        handleSetProject(project)
        handleOpenInvestModal()
      })
      .catch((e) => console.log(e))
  }

  const handleClaim = () => {
    getProjectByName(projectName)
      .then((project) => {
        handleSetProject(project)
        handleOpenClaimModal()
      })
      .catch((e) => console.log(e))
  }

  const areActionsColumn =
    ((status === PROJECT_STATUS.distributed ||
      status === PROJECT_STATUS.lottery) &&
      userStatus === USER_STATUS.applied) ||
    (status === PROJECT_STATUS.lottery &&
      userStatus === USER_STATUS.unauthorized)

  const areButtonsColumn =
    ((status === PROJECT_STATUS.lottery ||
      status === PROJECT_STATUS.distributed) &&
      userStatus === USER_STATUS.unauthorized) ||
    (status === PROJECT_STATUS.apply && userStatus === USER_STATUS.applied)

  return (
    <CardActionsComponent variant={variant}>
      {status === PROJECT_STATUS.upcoming && startDate && (
        <UpcomingDescription variant={variant}>
          {getFormattedUTCDateFromString(startDate)}
        </UpcomingDescription>
      )}
      <ActionsContainer column={areActionsColumn}>
        <ActionHelper
          status={status}
          applied={applied}
          amount={amount}
          location="main"
          column={areActionsColumn}
          allocation={allocation}
        />
        <ActionButtonsContainer column={areButtonsColumn}>
          <ActionButton
            location="main page"
            variant={variant}
            status={status}
            applied={applied}
            handleOpenInvestModal={handleApply}
            handleOpenBuyModal={handleBuy}
            handleOpenClaimModal={handleClaim}
            amount={amount}
            allocation={allocation}
          />
          <CardLink
            to={`projects/${projectName.toLowerCase()}/`}
            variant={variant}
          >
            <ActionDetailsButton variant={variant}>Details</ActionDetailsButton>
          </CardLink>
        </ActionButtonsContainer>
      </ActionsContainer>
    </CardActionsComponent>
  )
}

export default CardActions
