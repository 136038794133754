import React, { useContext, useEffect } from 'react'
import { AddressContainer, Address } from './ConnectWallet.styled'
import { shortenAddress } from '../../utils/shortenAddress'
import Button from '../Button'
import { connectionStatesList } from '../../hooks/useConnection'
import { ConnectionContext, UserAuthContext } from '../../contexts'
import Spinner from '../Spinner'

function ConnectWallet({
  variant,
  size,
  handleOpenInstallMetamask,
  handleMenuClose,
  disabled,
}) {
  const { user } = useContext(UserAuthContext)
  const { userAddress, switchChain, connectWallet, connectionState } =
    useContext(ConnectionContext)

  const isProperChain =
    connectionState === connectionStatesList.walletConnectedToProperChain
  const isOtherChain =
    connectionState === connectionStatesList.walletConnectedToOtherChain

  useEffect(() => {
    if (isProperChain && handleMenuClose !== undefined) {
      handleMenuClose()
    }
  }, [isProperChain, connectionState]) // eslint-disable-line react-hooks/exhaustive-deps

  if (userAddress) {
    if (isProperChain) {
      return (
        <AddressContainer
          status={user.ethAddress && user.ethAddress !== userAddress && 'wrong'}
          variant={variant}
        >
          <Address variant={variant}>
            {!user.ethAddress || user.ethAddress === userAddress
              ? shortenAddress(userAddress)
              : 'Wrong address'}
          </Address>
        </AddressContainer>
      )
    } else if (isOtherChain) {
      return (
        <Button
          disabled={disabled}
          variant={variant}
          size={size}
          onClick={switchChain}
        >
          {disabled ? (
            <Spinner size={'16px'} color={'white'} />
          ) : (
            ' Switch network'
          )}
        </Button>
      )
    }
  }

  return !window.ethereum ? (
    <Button variant={variant} size={size} onClick={handleOpenInstallMetamask}>
      Connect Wallet
    </Button>
  ) : (
    <Button
      disabled={disabled}
      size={size}
      variant={variant}
      onClick={connectWallet}
    >
      {disabled ? <Spinner size={'16px'} color={'white'} /> : 'Connect wallet'}
    </Button>
  )
}

export default ConnectWallet
