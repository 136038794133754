import {
  LightCardParagraph,
  CardContainer,
  CardDescription,
  CardTitle,
  InfoBlock,
  CardImage,
  CardContent,
  CardStatusTitle,
  PendingCardRelative,
  CardDetails,
  Status,
  TagsStatusButtonsContainer,
  ButtonsStatusContainer,
  TagsContainer,
  CardImageContainer,
} from './Card.styled'
import { getFormattedUTCDateFromString } from '../../utils/formatDate'
import CardHeaderComponent from './CardHeader'
import PendingCard from './PendingCard'
import CardActions from './CardActions'
import { StyledTag } from '../../components/Tag/Tag.styled'
import SocialButtons from '../SocialButtons'
import { PROJECT_STATUS } from '../../constants'

const Card = ({
  projectsData,
  variant,
  isMobile,
  handleOpenInvestModal,
  handleOpenBuyModal,
  handleOpenClaimModal,
  handleSetProject,
}) => {
  const tags = projectsData.tags.map((value) => (
    <StyledTag key={value} variant={variant}>
      {value}
    </StyledTag>
  ))

  return (
    <PendingCardRelative variant={variant}>
      <CardContainer status={projectsData.status} variant={variant}>
        <CardImageContainer>
          <CardImage src={projectsData.avatar} alt="avatar" variant={variant} />
        </CardImageContainer>
        {variant !== 'carousel' ||
          (isMobile && (
            <CardHeaderComponent tags={projectsData.tags} variant={variant} />
          ))}
        <CardContent variant={variant}>
          {variant === 'carousel' && (
            <TagsStatusButtonsContainer isMobile={isMobile} variant={variant}>
              {!isMobile && <TagsContainer>{tags}</TagsContainer>}
              <ButtonsStatusContainer isMobile={isMobile} variant={variant}>
                <SocialButtons
                  telegram={projectsData.telegram}
                  twitter={projectsData.twitter}
                  instagram={projectsData.instagram}
                  youtube={projectsData.youtube}
                  website={projectsData.website}
                  facebook={projectsData.facebook}
                  github={projectsData.github}
                  discord={projectsData.discord}
                  medium={projectsData.medium}
                  linkedin={projectsData.linkedin}
                />
                <Status status={projectsData.status}>
                  {projectsData.status === PROJECT_STATUS.distributed
                    ? 'Raised'
                    : projectsData.status}
                </Status>
              </ButtonsStatusContainer>
            </TagsStatusButtonsContainer>
          )}
          <CardTitle variant={variant}>{projectsData.name}</CardTitle>
          <CardDescription variant={variant} dangerouslySetInnerHTML={{ __html: projectsData.description }} />
          {projectsData.start_date && projectsData.end_date && (
            <CardDetails variant={variant}>
              <InfoBlock style={{ marginTop: '16px' }} variant={variant}>
                <LightCardParagraph color="light" variant={variant}>
                  {variant === 'carousel' ? 'Registration opens' : 'Reg opens'}
                </LightCardParagraph>
                <LightCardParagraph>
                  {getFormattedUTCDateFromString(projectsData.start_date)}
                </LightCardParagraph>
              </InfoBlock>
              {variant === 'carousel' && (
                <InfoBlock variant={variant}>
                  <LightCardParagraph color="light" variant={variant}>
                    Registration closes
                  </LightCardParagraph>
                  <LightCardParagraph>
                    {projectsData?.end_date}
                  </LightCardParagraph>
                </InfoBlock>
              )}
            </CardDetails>
          )}
          <CardActions
            projectName={projectsData.name}
            status={projectsData.status}
            variant={variant}
            startDate={projectsData.start_date}
            handleOpenInvestModal={handleOpenInvestModal}
            handleOpenBuyModal={handleOpenBuyModal}
            handleOpenClaimModal={handleOpenClaimModal}
            handleSetProject={handleSetProject}
            applied={projectsData.applied}
            amount={projectsData.application?.amount}
            allocation={projectsData.allocation}
          />
        </CardContent>
        {variant !== 'carousel' && (
          <CardStatusTitle status={projectsData.status}>
            {projectsData.status === PROJECT_STATUS.distributed
              ? 'Raised'
              : projectsData.status}
          </CardStatusTitle>
        )}
      </CardContainer>
      <PendingCard status={projectsData.status} />
    </PendingCardRelative>
  )
}

export default Card
