import jwtDecode from 'jwt-decode'
import { refreshToken } from '.'

const host = process.env.REACT_APP_API_HOST

const { fetch: originalFetch } = window

window.fetch = async (...args) => {
  let [resource, config] = args
  const authTokens = localStorage.getItem('authTokens')
    ? JSON.parse(localStorage.getItem('authTokens'))
    : null
  resource = `${host}${resource}`
  let isExpiredAccess, isExpiredRefresh

  if (authTokens) {
    isExpiredAccess =
      jwtDecode(authTokens.access).exp * 1000 - new Date() <= 5000
    isExpiredRefresh = jwtDecode(authTokens.refresh).exp * 1000 < new Date()
  }

  if (!config) {
    config = {}
  }

  config.headers = {
    ...config?.headers,
    'Content-Type': 'application/json',
  }

  if ((config.withAuth || config.withSoftAuth) && authTokens) {
    if (isExpiredRefresh) {
      localStorage.removeItem('authTokens')
      window.location.reload()
    }

    if (isExpiredAccess) {
      const { access } = await refreshToken(authTokens.refresh)
      authTokens.access = access
    }
    config.headers.authorization = `Bearer ${authTokens.access}`
  }

  if (config.withAuth && !authTokens) {
    return
  }

  const response = await originalFetch(resource, config)
  return response
}

export default window.fetch
