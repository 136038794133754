import React from 'react'
import { IconContainer, SocialLinkContainer } from './SocialButtons.styled'

function SocialButtons({
  telegram,
  twitter,
  instagram,
  youtube,
  website,
  facebook,
  github,
  discord,
  medium,
  linkedin,
  variant,
}) {
  return (
    <SocialLinkContainer variant={variant}>
      {youtube && (
        <a href={youtube} target="_blank" rel="noreferrer">
          <IconContainer>
            <img
              src={require('../../images/youtubeIcon.svg').default}
              alt="youtube"
              width="20px"
            />
          </IconContainer>
        </a>
      )}
      {github && (
        <a href={github} target="_blank" rel="noreferrer">
          <IconContainer>
            <img
              src={require('../../images/githubIcon.svg').default}
              alt="github"
            />
          </IconContainer>
        </a>
      )}
      {facebook && (
        <a href={facebook} target="_blank" rel="noreferrer">
          <IconContainer>
            <img
              src={require('../../images/facebookIcon.svg').default}
              alt="facebook"
            />
          </IconContainer>
        </a>
      )}
      {website && (
        <a href={website} target="_blank" rel="noreferrer">
          <img
            src={require('../../images/WebSite.svg').default}
            alt="website"
          />
        </a>
      )}
      {instagram && (
        <a href={instagram} target="_blank" rel="noreferrer">
          <IconContainer>
            <img
              src={require('../../images/instagramIcon.svg').default}
              alt="instagram"
              width="20px"
            />
          </IconContainer>
        </a>
      )}
      {telegram && (
        <a href={telegram} target="_blank" rel="noreferrer">
          <img
            src={require('../../images/TelegramIcon.svg').default}
            alt="Telegram"
          />
        </a>
      )}
      {twitter && (
        <a href={twitter} target="_blank" rel="noreferrer">
          <img
            src={require('../../images/TwitterIcon.svg').default}
            alt="Twitter"
          />
        </a>
      )}
      {discord && (
        <a href={discord} target="_blank" rel="noreferrer">
          <IconContainer>
            <img
              src={require('../../images/discord.svg').default}
              alt="Discord"
            />
          </IconContainer>
        </a>
      )}
      {medium && (
        <a href={medium} target="_blank" rel="noreferrer">
          <IconContainer>
            <img
              src={require('../../images/mediumIcon.svg').default}
              alt="medium"
              width="20px"
            />
          </IconContainer>
        </a>
      )}
      {linkedin && (
        <a href={linkedin} target="_blank" rel="noreferrer">
          <IconContainer>
            <img
              src={require('../../images/linkedinIcon.svg').default}
              alt="linkedin"
              width="20px"
            />
          </IconContainer>
        </a>
      )}
    </SocialLinkContainer>
  )
}

export default SocialButtons
