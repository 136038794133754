import { useContext, useEffect, useRef } from 'react'
import {
  Circle,
  IconClose,
  NotificationPopUpContainer,
} from './NotificationPopUp.styled'
import { UserAuthContext } from '../../contexts/UserAuthContext'

const NotificationPopUp = ({ isOpen, message, variant, isError }) => {
  const { authPopup, setAuthPopup } = useContext(UserAuthContext)
  const ref = useRef()

  const handleClose = () => {
    ref.current.style.display = 'none'
    setAuthPopup((prev) => {
      return {
        isError: false,
        message: '',
        open: false,
      }
    })
  }

  useEffect(() => {
    if ((variant === 'registration' && authPopup.open) || isOpen) {
      ref.current.style.display = 'flex'
      setTimeout(() => {
        if (ref.current) {
          handleClose()
        }
      }, 15000)
    }
    return () => {
      clearTimeout()
      setAuthPopup((prev) => {
        return {
          ...prev,
          open: false,
        }
      })
    }
  }, [authPopup.open, isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <NotificationPopUpContainer
      ref={ref}
      variant={!variant !== 'registration' && 'bottom'}
      isError={authPopup.isError || isError}
    >
      <Circle isError={authPopup.isError || isError}>
        <img
          src={require('../../images/emailIcon.png')}
          width="36"
          alt="email"
        />
      </Circle>
      <div>
        <h2>Notification</h2>
        <p>{authPopup.message ? authPopup.message : message}</p>
      </div>
      <IconClose onClick={handleClose}></IconClose>
    </NotificationPopUpContainer>
  )
}

export default NotificationPopUp
