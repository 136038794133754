import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StatisticsBlock = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 40px 0;
  flex-wrap: wrap;
  row-gap: 32px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    margin: 0 16px;
    button {
      width: 100%;
      max-width: 400px;
    }
  }
`

export const DataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 24px;

  div:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }

  div:first-child {
    padding-left: 0;
  }

  @media (max-width: 768px) {
    overflow: hidden;

    div {
      padding-left: 16px;

      :first-child {
        padding-left: 16px;
      }
    }
  }
`

export const DataBlockItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  gap: 16px;

  @media (max-width: 768px) {
    align-items: center;
    padding: 0 16px;
    flex-grow: 1;
    margin: 0 -1px -1px 0;
  }
`

export const DataBlockTitle = styled.h1`
  font-family: 'Roboto Mono', monospace;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  margin: 0;
`

export const DataBlockText = styled.p`
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  margin: 0;
`

export const InputLabel = styled.p`
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 8px;
    width: 100%;
  }
`

export const SubscribeBlock = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 15px;

  @media (max-width: 768px) {
    justify-content: center;
    gap: 8px;
    flex-wrap: wrap;
    margin: 0 20px;
  }
`

export const SubscribeInput = styled.input`
  border: 1px solid #f3f3f3;
  border-radius: 64px;
  padding: 13px 24px;
  color: #000000;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  margin-left: 24px;
  margin-right: 8px;
  width: 218px;
  box-sizing: border-box;
  &::placeholder {
    opacity: 0.2;
  }
  &:focus {
    outline: 1px solid black;
  }

  @media (max-width: 768px) {
    padding: 13px 16px;
    width: 100%;
    margin: 0;
  }
`

export const SignUpLink = styled(Link)`
  text-decoration: none;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const StatisticsLink = styled.a`
  text-decoration: none;
  @media (max-width: 768px) {
    width: 100%;
  }
`
