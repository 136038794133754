import {
  FooterContainer,
  FooterLogo,
  FooterLink,
  FooterLinkContainer,
  Container,
} from './Footer.styled'

const Footer = () => {
  return (
    <FooterContainer>
      <Container>
        <FooterLogo
          src={require('../../images/StepLaunch.svg').default}
          alt="footer_logo"
        />
        <FooterLinkContainer>
          <FooterLink to={'/faq'}> FAQ </FooterLink>
          <FooterLink to={'/help'}> Help </FooterLink>
          <FooterLink> Cookie Policy </FooterLink>
          <FooterLink>Terms of Service</FooterLink>
        </FooterLinkContainer>
      </Container>
    </FooterContainer>
  )
}

export default Footer
