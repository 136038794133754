import { StyledButton, Icon } from './Button.styled'

function Button({ children, disabled, onClick, variant, icon, size, ...rest }) {
  return (
    <StyledButton
      disabled={disabled}
      onClick={onClick}
      variant={variant}
      size={size}
      {...rest}
    >
      {icon && <Icon>{icon}</Icon>}
      {children}
    </StyledButton>
  )
}

export default Button
