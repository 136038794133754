import fetch from './fetch'

export async function getUser() {
  return await fetch(`/users/me/`, {
    method: 'GET',
    withAuth: true,
  })
    .then(async (response) => {
      if (response.ok) {
        return response
      }
      const err = await response.json()
      if (err.detail === 'User not found') {
        localStorage.removeItem('authTokens')
        window.location.reload()
      }
      throw new Error(`Error: ${response.status}`)
    })
    .then((response) => response.json())
    .catch((err) => {
      throw new Error(err)
    })
}

export async function linkEthAddress(id, address) {
  return await fetch(`/users/${id}/`, {
    method: 'PUT',
    withAuth: true,
    body: JSON.stringify({
      id,
      eth_address: address,
    }),
  })
    .then(async (response) => {
      const res = await response.json()
      if (response.ok) {
        return res
      }
      if (response.status === 400 && res.eth_address) {
        throw new Error(res.eth_address)
      }
    })
    .catch((err) => {
      throw new Error(err.message)
    })
}
