import styled from 'styled-components'

export const AddressContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 7px 8px;
  border-radius: ${({ variant }) => {
    switch (variant) {
      case 'burger':
        return '48px'
      default:
        return '64px'
    }
  }};
  display: flex;
  align-items: center;
  padding: 15px 36px;
  border: ${({ status }) => {
    switch (status) {
      case 'wrong':
        return '1px solid transparent'
      default:
        return '1px solid #cccccc'
    }
  }};
  background: ${({ status }) => {
    switch (status) {
      case 'wrong':
        return 'rgba(238,82,83,0.1)'
      default:
        return 'unset'
    }
  }};
  width: ${({ variant }) => {
    switch (variant) {
      case 'burger':
        return '320px'
      default:
        return 'unset'
    }
  }};
  height: ${({ variant }) => {
    switch (variant) {
      case 'burger':
        return '80px'
      default:
        return 'unset'
    }
  }};

  @media (max-width: 768px) {
    padding: 14px 10px;
  }
`

export const Address = styled.p`
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: ${({ variant }) => {
    switch (variant) {
      case 'burger':
        return '24px'
      default:
        return '12px'
    }
  }};
  line-height: 14px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: black;
  margin: 0;
`
