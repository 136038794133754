import React from 'react'
import useConfig from '../hooks/useConfig'

export const ConfigContext = React.createContext({})

const ConfigProvider = ({ children }) => {
  const { config } = useConfig()
  return (
    <ConfigContext.Provider
      value={{
        config,
      }}
    >
      {children}
    </ConfigContext.Provider>
  )
}

export default ConfigProvider
