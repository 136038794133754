import React from 'react'
import { TeamContainer, WebContainer, Title, NoTeamText } from './ProjectTeam.styled'

function ProjectTeam({ name, team }) {
  return (
    <TeamContainer>
      <Title>{name} Team</Title>
      {!team || team.length === 0 ? (
        <NoTeamText>There is no information on team members</NoTeamText>
      ) : (
        <WebContainer dangerouslySetInnerHTML={{ __html: team }} />
      )}
    </TeamContainer>
  )
}

export default ProjectTeam
