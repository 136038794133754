import { useContext } from 'react'
import {
  AlertBannerInfo,
  AdddresLinkedNotification,
  UniqueAdddresErrorNotification,
  ETH_LINKING_STATUS,
  KYC_STATUS,
} from '../../constants'
import { useLocation } from 'react-router-dom'
import NotificationPopUp from '../NotificationPopUp'
import {
  ConnectionContext,
  ConfigContext,
  UserAuthContext,
} from '../../contexts'
import useLinkedAddress from '../../hooks/useLinkedAddress'
import AlertBanner from '../AlertBanner'
import { Container } from './NotifBanners.styled'
import Spinner from '../Spinner'

function NotifBanners({ handleOpenInstallMetamask, linkAddressCallback }) {
  const { user, isLogined, ethLinkingStatus } = useContext(UserAuthContext)
  const { userAddress } = useContext(ConnectionContext)
  const { config } = useContext(ConfigContext)
  const { handleSet } = useLinkedAddress({
    config,
    handleOpenInstallMetamask,
    linkAddressCallback,
  })
  const location = useLocation()

  const userBanners = user.banners?.map((banner) => (
    <AlertBanner
      key={banner.title}
      data={{
        title: banner.title,
        description: banner.body,
        actionButtonText: banner.action_text,
      }}
      variant={banner.type.toLowerCase()}
      handleClick={() => window.open(banner.action_link)}
    />
  ))

  const wrongAddress =
    user && userAddress && user.ethAddress && userAddress !== user.ethAddress

  const displayKycBanners = user && user.ethAddress

  const isVisible =
    isLogined &&
    (location.pathname === '/' || location.pathname.includes('/projects/'))

  return (
    isVisible && (
      <Container>
        {displayKycBanners && user.kycStatus === KYC_STATUS.notSubmitted && (
          <AlertBanner data={AlertBannerInfo('kycNotSubmitted')} />
        )}
        {displayKycBanners && user.kycStatus === KYC_STATUS.pending && (
          <AlertBanner data={AlertBannerInfo('kycPending')} variant="pending" />
        )}
        {displayKycBanners && user.kycStatus === KYC_STATUS.rejected && (
          <AlertBanner data={AlertBannerInfo('kycRejected')} />
        )}
        {user.ethAddress === undefined ? (
          <Spinner />
        ) : user && !user.ethAddress ? (
          <AlertBanner
            handleClick={handleSet}
            data={AlertBannerInfo('address')}
          />
        ) : null}
        {wrongAddress && <AlertBanner data={AlertBannerInfo('wrongAddress')} />}
        {user.ethAddress && user.balance < config.min_stfitfi_balance && (
          <AlertBanner
            handleClick={() => window.open('https://staking.step.app/')}
            data={AlertBannerInfo('zeroBalance', config.min_stfitfi_balance)}
          />
        )}
        {userBanners}
        <NotificationPopUp
          isOpen={ethLinkingStatus === ETH_LINKING_STATUS.success}
          message={AdddresLinkedNotification}
        />
        <NotificationPopUp
          isOpen={ethLinkingStatus === ETH_LINKING_STATUS.uniqueError}
          message={UniqueAdddresErrorNotification}
          isError={true}
        />
      </Container>
    )
  )
}

export default NotifBanners
