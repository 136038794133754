import styled from 'styled-components'

export const MainContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 48px;

  @media (max-width: 768px) {
    padding: 24px 0;
  }
`
