import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../Button'

export const CardContainer = styled.div`
  background: #f3f3f3;
  border-radius: 24px;
  position: relative;
  user-select: none;
  @media (min-width: 986px) {
    min-height: 508px;
  }
  max-width: ${({ variant }) => {
    return variant === 'carousel' ? '1272px' : 'unset'
  }};
  width: ${({ variant }) => {
    return variant === 'carousel' ? '100%' : '327px'
  }};
  filter: ${({ status }) => {
    return status === 'pending' ? 'blur(12px)' : 'unset'
  }};
  pointer-events: ${({ status }) => {
    return status === 'pending' ? 'none' : 'unset'
  }};
  display: ${({ variant }) => {
    return variant === 'carousel' ? 'flex' : 'unset'
  }};
  gap: ${({ variant }) => {
    return variant === 'carousel' ? '68px' : 'unset'
  }};
  padding-right: ${({ variant }) => {
    return variant === 'carousel' ? '20px' : 'unset'
  }};
  display: ${({ variant }) => {
    return variant === 'carousel' ? 'flex' : 'unset'
  }};
  @media (max-width: 985px) {
    flex-direction: ${({ variant }) => {
      return variant === 'carousel' ? 'column' : '24px'
    }};
    padding-right: unset;
    gap: ${({ variant }) => {
      return variant === 'carousel' ? '24px' : 'unset'
    }};
    max-width: ${({ variant }) => {
      return variant === 'carousel' ? '360px' : 'unset'
    }};
    margin: ${({ variant }) => {
      return variant === 'carousel' ? '0 auto' : 'unset'
    }};
  }
`

export const CardTitle = styled.h2`
  font-family: 'Roboto Mono', monospace;
  text-transform: uppercase;
  font-weight: 700;
  font-size: ${({ variant }) => {
    return variant === 'carousel' ? '40px' : '24px'
  }};
  line-height: 100%;
  letter-spacing: 0.03em;
  @media (max-width: 985px) {
    font-size: ${({ variant }) => {
      return variant === 'carousel' ? '32px' : '24px'
    }};
  }
`

export const CardDescription = styled.p`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.03em;
  line-height: 120%;
  margin: ${({ variant }) => {
    return variant === 'carousel' ? '16px 0 20px' : '18px 0'
  }};
  color: rgba(0, 0, 0, 0.4);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-max-lines: 2;
  @media (max-width: 1137px) {
    padding: 0 20px 0 0;
  }
  @media (max-width: 985px) {
    line-height: ${({ variant }) => {
      return variant === 'carousel' ? '140%' : '120%'
    }};
  }
`

export const LightCardParagraph = styled.p`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.03em;
  line-height: 130%;
  color: rgba(0, 0, 0, 0.4);
  color: ${({ color }) => {
    switch (color) {
      case 'light':
        return 'rgba(0, 0, 0, 0.4)'
      default:
        return 'black'
    }
  }};
  margin-bottom: ${({ variant }) => {
    return variant === 'carousel' ? '8px' : '5px'
  }};
  max-width: ${({ color, variant }) => {
    if (color === 'light' && variant === 'carousel') {
      return '156px'
    } else {
      return 'auto'
    }
  }};
  width: ${({ color, variant }) => {
    if (color === 'light' && variant === 'carousel') {
      return '100%'
    } else {
      return 'auto'
    }
  }};
  @media (max-width: 985px) {
    max-width: auto;
    width: auto;
  }
`

export const InfoBlock = styled.div`
  display: flex;
  justify-content: ${({ variant }) => {
    return variant === 'carousel' ? 'flex-start' : 'space-between'
  }};
  align-items: center;
  gap: ${({ variant }) => {
    return variant === 'carousel' ? '16px' : 'unset'
  }};
  @media (max-width: 985px) {
    align-items: flex-start;
    justify-content: space-between;
    gap: unset;
  }
`

export const CardDetails = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: ${({ variant }) => {
    return variant === 'carousel' ? '1px solid rgba(0, 0, 0, 0.2)' : 'unset'
  }};
  padding-bottom: ${({ variant }) => {
    return variant === 'carousel' ? '15px' : 'unset'
  }};
`

export const CardActionsComponent = styled(InfoBlock)`
  margin-top: 32px;
  flex-direction: column;
  align-items: unset;
  gap: ${({ variant }) => {
    return variant === 'carousel' ? '12px' : '10px'
  }};
  max-width: ${({ variant }) => {
    return variant === 'carousel' ? 'auto' : 'unset'
  }};
  a {
    width: ${({ status, variant }) => {
      if (
        variant !== 'carousel' &&
        (status !== 'registration' ||
          status !== 'on sale' ||
          status !== 'upcoming')
      ) {
        return '100%'
      } else {
        return 'auto'
      }
    }};
  }
  @media (max-width: 556px) {
    flex-direction: ${({ variant }) => {
      return variant === 'carousel' ? 'unset' : 'column'
    }};
    row-gap: 10px;
    a {
      margin-left: 0px;
      width: 100% !important;
    }
  }
  @media (max-width: 985px) {
    margin-top: ${({ variant }) => {
      return variant === 'carousel' ? '24px' : '32px'
    }};
    gap: 10px;
    a,
    button {
      width: 100% !important;
    }
  }
`

export const CardButton = styled(Button)`
  border: none;
`

export const CardHeaderButton = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  background: #ffffff;
  padding: 10px;
  font-weight: 500;
  letter-spacing: 0.03em;
  border-radius: 8px;
  width: max-content;
`

export const ActionDetailsButton = styled(CardButton)`
  width: ${({ variant }) => {
    return variant === 'carousel' ? 'auto' : '100%'
  }};
  height: ${({ variant }) => {
    return variant === 'carousel' ? 'auto' : '100%'
  }};
  padding: ${({ variant }) => {
    return variant === 'carousel' ? '16px 57px' : 'unset'
  }};
  background: black !important;
  font-size: 12px;
  color: white !important;
  font-weight: 500;
  line-height: 100%;
  @media (max-width: 985px) {
    width: 50%;
    padding: ${({ variant }) => {
      return variant === 'carousel' ? '16px 51px' : 'unset'
    }};
  }
`

export const PendingCardButton = styled(CardButton)`
  border-radius: 30px;
  padding: 16px 40px;
  &:hover {
    border: none;
  }
`

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px;
  position: absolute;
  top: 16px;
  @media (max-width: 985px) {
    top: ${({ variant }) => {
      return variant === 'carousel' ? '20px' : '16px'
    }};
    left: ${({ variant }) => {
      return variant === 'carousel' ? '20px' : 'unset'
    }};
    padding: ${({ variant }) => {
      return variant === 'carousel' ? '0' : '0 16px'
    }};
  }
  @media (max-width: 390px) {
    align-items: unset;
  }
`

export const CardHeaderTags = styled.div`
  display: flex;
  gap: 10px;
  max-width: 320px;
  flex-wrap: wrap;
  @media (max-width: 390px) {
    flex-direction: ${({ variant }) => {
      return variant === 'carousel' ? 'unset' : 'column'
    }};
    align-items: ${({ variant }) => {
      return variant === 'carousel' ? 'flex-start' : 'flex-end'
    }};
  }
`

export const CardLink = styled(Link)`
  color: black;
  text-decoration: none;
  width: ${({ variant }) => {
    return variant === 'carousel' ? 'auto' : '50%'
  }};
  height: 44px;
`

export const CardHeaderImage = styled.img`
  width: 32px;
  height: 32px;
`

export const CardImage = styled.img`
  width: ${({ variant }) => {
    return variant === 'carousel' ? 'unset' : '100%'
  }};
  height: ${({ variant }) => {
    return variant === 'carousel' ? 'unset' : '100%'
  }};
  max-width: ${({ variant }) => {
    return variant === 'carousel' ? '508px' : 'unset'
  }};
  max-height: ${({ variant }) => {
    return variant === 'carousel' ? '508px' : 'unset'
  }};
  min-width: ${({ variant }) => {
    return variant === 'carousel' ? '360px' : 'unset'
  }};
  min-height: ${({ variant }) => {
    return variant === 'carousel' ? '360px' : 'unset'
  }};
  border-radius: ${({ variant }) => {
    return variant === 'carousel' ? '24px' : '24px 24px 0 0'
  }};
  filter: brightness(95%);
  @media (max-width: 985px) {
    max-width: ${({ variant }) => {
      return variant === 'carousel' ? '360px' : 'unset'
    }};
    width: 100%;
  }
`

export const CardStatusTitle = styled.p`
  position: absolute;
  padding: 10px 12px;
  border-radius: 8px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  top: 308px;
  right: 5%;
  color: ${({ status }) => (status === 'upcoming' ? 'black' : 'white')};
  background: ${({ status }) => {
    switch (status) {
      case 'upcoming':
        return '#F9D549'
      case 'lottery':
        return '#8149F9'
      case 'on sale':
        return '#DC5757'
      case 'distributed':
        return '#44944A'
      case 'vesting':
        return '#995DA4'
      case 'registration':
        return '#4F79E4'
      default:
        return 'black'
    }
  }};
`

export const PendingCardContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(0, 0, 0, 0.6);
`

export const PendingTitle = styled.h1`
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`

export const PendingCardRelative = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  margin: ${({ variant }) => {
    return variant === 'carousel' ? '60px 30px' : 'unset'
  }};
  @media (max-width: 985px) {
    margin: ${({ variant }) => {
      return variant === 'carousel' ? '40px 0' : 'unset'
    }};
    min-height: 662px;
  }
`

export const CardContent = styled.div`
  padding: ${({ variant }) => {
    return variant === 'carousel' ? '48px 0' : '16px 16px 28px 16px'
  }};
  max-width: ${({ variant }) => {
    return variant === 'carousel' ? '572px' : 'unset'
  }};
  width: ${({ variant }) => {
    return variant === 'carousel' ? '100%' : 'unset'
  }};
  @media (max-width: 985px) {
    padding: ${({ variant }) => {
      return variant === 'carousel' ? '0 20px 32px' : '16px 16px 28px 16px'
    }};
  }
`

export const UpcomingDescription = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  width: ${({ variant }) => {
    return variant === 'carousel' ? 'auto' : '90%'
  }};
`

export const Status = styled.div`
  padding: 10px 12px;
  border-radius: 8px;
  font-family: 'Roboto Mono', monospace;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: ${({ status }) => {
    switch (status) {
      case 'upcoming':
        return 'black'
      case 'lottery':
        return 'white'
      case 'on sale':
        return 'white'
      case 'distributed':
        return 'white'
      case 'vesting':
        return 'white'
      case 'registration':
        return 'white'
      default:
        return 'black'
    }
  }};
  background: ${({ status }) => {
    switch (status) {
      case 'upcoming':
        return '#F9D549'
      case 'lottery':
        return '#8149F9'
      case 'on sale':
        return '#DC5757'
      case 'distributed':
        return '#44944A'
      case 'vesting':
        return '#995DA4'
      case 'registration':
        return '#4F79E4'
      default:
        return 'black'
    }
  }};
`

export const TagsStatusButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 48px;
  @media (max-width: 985px) {
    margin-bottom: ${({ isMobile, variant }) => {
      return isMobile && variant === 'carousel' ? '24px' : '48px'
    }};
  }
`

export const ButtonsStatusContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  @media (max-width: 1174px) {
    align-items: flex-start;
  }
  @media (max-width: 985px) {
    width: ${({ isMobile, variant }) => {
      return isMobile && variant === 'carousel' ? '100%' : 'unset'
    }};
    justify-content: ${({ isMobile, variant }) => {
      return isMobile && variant === 'carousel' ? 'space-between' : 'unset'
    }};
  }
`

export const TagsContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-right: 10px;
  max-width: 250px;
`

export const PendingText = styled.p`
  font-family: 'Roboto Mono', monospace;
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`

export const PendingLink = styled(Link)`
  text-decoration: none;
`

export const DiscordLink = styled.a`
  text-decoration: none;
`

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row-reverse')};
  justify-content: flex-end;
  width: 100%;
  gap: 16px;
  @media (max-width: 985px) {
    flex-direction: column;
  }
`

export const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  @media (max-width: 768px) {
    flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  }
`

export const CardImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1356px) {
    img {
      width: 360px;
    }
  }
`
