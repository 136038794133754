import {
  PendingCardButton,
  PendingTitle,
  PendingCardContainer,
  PendingText,
  PendingLink,
  DiscordLink,
} from './Card.styled'
import { UserAuthContext, ConfigContext } from '../../contexts'
import { useContext } from 'react'

const PendingCard = ({ status }) => {
  const { isLogined } = useContext(UserAuthContext)
  const { config } = useContext(ConfigContext)

  return (
    <>
      {status === 'pending' && (
        <PendingCardContainer>
          <PendingTitle>Coming soon</PendingTitle>
          <PendingText>
            {isLogined
              ? "Join our discord so you don't miss out"
              : 'Join us to stay tuned'}
          </PendingText>
          {isLogined ? (
            <DiscordLink
              target="_blank"
              rel="noreferrer"
              href={config.discord_invite_link}
            >
              <PendingCardButton variant="transparent">
                discord
              </PendingCardButton>
            </DiscordLink>
          ) : (
            <>
              <PendingLink to="/signin">
                <PendingCardButton variant="transparent">
                  Sign in
                </PendingCardButton>
              </PendingLink>
              <PendingLink to="/signup">
                <PendingCardButton variant="transparent">
                  Sign up
                </PendingCardButton>
              </PendingLink>
            </>
          )}
        </PendingCardContainer>
      )}
    </>
  )
}

export default PendingCard
