import styled from 'styled-components'

export const AlertBannerContainer = styled.div`
  display: flex;
  background: ${({ variant }) => {
    switch (variant) {
      case 'pending':
        return 'rgba(249,213,73, 0.3)'
      case 'success':
        return 'rgba(79, 121, 228, 0.1)'
      default:
        return 'rgba(238, 82, 83, 0.1)'
    }
  }};
  border-radius: 24px;
  padding: 24px 40px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 120px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: unset;
  }
`

export const AlertIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ variant }) => {
    switch (variant) {
      case 'pending':
        return 'black'
      case 'success':
        return '#4F79E4'
      default:
        return '#EE5253'
    }
  }};
  border-radius: 16px;
  width: 60px;
  height: 60px;
  @media (max-width: 768px) {
    width: 48px;
    height: 48px;
  }
`

export const AlertBannerMessage = styled.div`
  margin-left: 20px;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`

export const AlertBannerMessageTitle = styled.h2`
  font-family: 'Roboto Mono', monospace;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: black;
  @media (max-width: 768px) {
    font-size: 16px;
    margin: auto 16px;
  }
`

export const AlertBannerMessageText = styled.p`
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.8);
  margin-top: 4px;
  @media (max-width: 768px) {
    margin: 16px 0;
    font-size: 12px;
  }

  span {
    color: ${({ variant }) => {
      switch (variant) {
        case 'success':
          return 'rgb(79, 121, 228)'
        case 'warning':
          return '#EE5253'
        default:
          return 'inherit'
      }
    }};
  }
`

export const AlertIconMessageContainer = styled.div`
  display: flex;
  align-items: center;

  a {
    color: rgb(79, 121, 228);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-self: flex-start;
`

export const AlertIcon = styled.img`
  width: 28px;
  height: 28px;
  margin: auto;
  @media (max-width: 768px) {
    width: 22px;
    height: 22px;
    margin: auto;
  }
`

export const Icon = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: white;
    width: 38px;
    height: 38px;
    @media (max-width: 768px) {
      width: 32px;
      height: 32px;
    }
  }
`

export const AlertButtonContainer = styled.div`
  align-self: flex-end;

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const AccentText = styled.span`
  color: rgb(79, 121, 228);
`
