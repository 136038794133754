import React from 'react'
import {
  MetricsContainer,
  NoMetricsText,
  WebContainer,
  Title,
} from './TabMetrics.styled'

function TabMetrics({
  tokenDistribution,
  tokenReleaseSchedule,
  salesRoundDetails,
  ath,
  roi,
}) {
  return (
    <MetricsContainer>
      <Title>Token distribution</Title>
      {!tokenDistribution ? (
        <NoMetricsText>
          There is no information on project token distribution
        </NoMetricsText>
      ) : (
        <WebContainer dangerouslySetInnerHTML={{ __html: tokenDistribution }} />
      )}
      <Title>Sales round details</Title>
      {!salesRoundDetails || salesRoundDetails.length === 0 ? (
        <NoMetricsText>
          There is no information on project metrics
        </NoMetricsText>
      ) : (
        <WebContainer dangerouslySetInnerHTML={{ __html: salesRoundDetails }} />
      )}
      <Title>Token release schedule</Title>
      {!tokenReleaseSchedule ? (
        <NoMetricsText>
          There is no information on token release schedule
        </NoMetricsText>
      ) : (
        <WebContainer dangerouslySetInnerHTML={{ __html: tokenReleaseSchedule }} />
      )}
      <Title>ATH</Title>
      {!ath ? (
        <NoMetricsText>
          There is no information on ATH
        </NoMetricsText>
      ) : (
        <WebContainer dangerouslySetInnerHTML={{ __html: ath }} />
      )}
      <Title>ROI</Title>
      {!roi ? (
        <NoMetricsText>
          There is no information on ROI
        </NoMetricsText>
      ) : (
        <WebContainer dangerouslySetInnerHTML={{ __html: roi }} />
      )}
    </MetricsContainer>
  )
}

export default React.memo(TabMetrics)
