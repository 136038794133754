import React from 'react'
import {
  CardHeader,
  CardHeaderButton,
  CardHeaderImage,
  CardHeaderTags,
} from './Card.styled'

const CardHeaderComponent = React.memo(({ tags, variant }) => {
  return (
    <CardHeader variant={variant}>
      {variant !== 'carousel' && (
        <CardHeaderImage src={require('../../images/cardLogo.png')} />
      )}
      <CardHeaderTags variant={variant}>
        {tags && tags.length
          ? tags.map((tag) => (
              <CardHeaderButton key={tag} variant="transparent">
                {tag}
              </CardHeaderButton>
            ))
          : null}
      </CardHeaderTags>
    </CardHeader>
  )
})

export default CardHeaderComponent
