import React from 'react'
import { MenuBlock, Background, CloseButton } from './Menu.styled'
import { Close } from '@mui/icons-material'

function Menu({ handleClick, children }) {
  return (
    <>
      <MenuBlock>
        <CloseButton onClick={handleClick}>
          <Close />
        </CloseButton>
        {children}
      </MenuBlock>
      <Background />
    </>
  )
}

export default Menu
