import React from 'react'
import { Container } from './BurgerMenu.styled'
import Button from '../Button'
import { HeaderLink } from '../Header/Header.styled'
import ConnectWallet from '../ConnectWallet'
import StakedFitfi from '../StakedFitfi'

function BurgerMenu({
  isOpen,
  menuRef,
  handleClose,
  handleOpenInstallMetamask,
}) {
  return (
    <Container ref={menuRef} isOpen={isOpen}>
      <StakedFitfi variant="menu" />
      <ConnectWallet
        variant="burger"
        handleOpenInstallMetamask={handleOpenInstallMetamask}
        handleMenuClose={handleClose}
      />
      <HeaderLink
        href="https://staking.step.app/"
        target="_blank"
        rel="noreferrer"
      >
        <Button variant="burger" onClick={handleClose}>
          Stake fitfi
        </Button>
      </HeaderLink>
      <HeaderLink
        href="https://coinmarketcap.com/currencies/step-app/markets/"
        target="_blank"
        rel="noreferrer"
      >
        <Button variant="burger" onClick={handleClose}>
          Buy fitfi
        </Button>
      </HeaderLink>
      <HeaderLink
        href="https://app.step.exchange/#/swap"
        target="_blank"
        rel="noreferrer"
      >
        <Button variant="burger" onClick={handleClose}>
          Swap
        </Button>
      </HeaderLink>
    </Container>
  )
}

export default BurgerMenu
