import React, { useContext } from 'react'
import { ConnectionContext, UserAuthContext } from '../../contexts'
import { Balance } from './StakedFitfi.styled'
import { connectionStatesList } from '../../hooks/useConnection'
import Spinner from '../Spinner'

function StakedFitfi({ variant }) {
  const { isLogined } = useContext(UserAuthContext)
  const { stFitfiBalance, userAddress, connectionState } =
    useContext(ConnectionContext)

  const isProperChain =
    connectionState === connectionStatesList.walletConnectedToProperChain

  const balance =
    stFitfiBalance === undefined ? (
      <Spinner size={variant === 'menu' ? '24px' : '12px'} />
    ) : (
      Math.floor(stFitfiBalance)
    )

  return isLogined && userAddress && isProperChain ? (
    <Balance variant={variant}>Staked {balance} FITFI</Balance>
  ) : null
}

export default StakedFitfi
