import styled from 'styled-components'

export const Container = styled.div`
  display: none;

  @media (max-width: 1220px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};

    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;

    width: 100vw;
    height: calc(100vh - 80px);
    background: white;

    z-index: 1;
  }
`
