import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const ProjectPageContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 60px 48px;
  gap: 60px;
  box-sizing: border-box;
  max-width: 1440px;
  width: 100%;
  margin: 0px auto;
  @media (max-width: 820px) {
    max-width: 360px;
    padding: 32px 20px;
    flex-direction: column;
    align-items: center;
    margin: auto;
    gap: 16px;
  }
`

export const BackButtonLink = styled(Link)`
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: black;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 820px) {
    margin-right: auto;
    margin-bottom: 8px;
  }
`

export const TagsStatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  align-items: flex-start;
  @media (max-width: 820px) {
    margin-bottom: 24px;
  }
`

export const Status = styled.div`
  padding: 10px 12px;
  border-radius: 8px;
  font-family: 'Roboto Mono', monospace;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: ${({ status }) => {
    switch (status) {
      case 'upcoming':
        return 'black'
      case 'lottery':
        return 'white'
      case 'on sale':
        return 'white'
      case 'distributed':
        return 'white'
      case 'vesting':
        return 'white'
      case 'registration':
        return 'white'
      default:
        return 'black'
    }
  }};
  background: ${({ status }) => {
    switch (status) {
      case 'upcoming':
        return '#F9D549'
      case 'lottery':
        return '#8149F9'
      case 'on sale':
        return '#DC5757'
      case 'distributed':
        return '#44944A'
      case 'vesting':
        return '#995DA4'
      case 'registration':
        return '#4F79E4'
      default:
        return 'black'
    }
  }};
`

export const ProjectImage = styled.img`
  max-width: 636px;
  max-height: 636px;
  min-width: 320px;
  min-height: 320px;
  border-radius: 24px;
  @media (max-width: 820px) {
    width: 320px;
    height: 320px;
  }
`

export const ProjectInfoCard = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 37px;
  max-width: 362px;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 820px) {
    padding-top: 0;
  }
`

export const TabsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin: 0 auto;
  max-width: 1148px;
  width: auto;
  @media (max-width: 820px) {
    margin-top: 27px;
  }
`

export const Title = styled.div`
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 100%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 12px;
`

export const Description = styled.div`
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.5;
`

export const TagsContainer = styled.div`
  display: flex;
  max-width: 250px;
  flex-wrap: wrap;
  gap: 12px;
`

export const ProjectTotalPrice = styled.div`
  display: flex;
  margin-top: 32px;
  gap: 16px;
`

export const ProjectPrice = styled.h1`
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 60px;
  line-height: 100%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: black;
`

export const ActionButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

export const SocialContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 11px;
  margin-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 19px 0;
`

export const SocialText = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
`
