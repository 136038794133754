import {
  Modal,
  ModalContainer,
  ButtonContainer,
  Text,
  LinkModalErrorText,
  LinkModalErrorContainer,
} from './Modal.styled'
import Button from '../Button'

import { useContext, useEffect } from 'react'
import { ConnectionContext, UserAuthContext } from '../../contexts'
import { shortenAddress } from '../../utils/shortenAddress'
import {
  UniqueAdddresErrorNotification,
  ETH_LINKING_STATUS,
} from '../../constants'

function LinkAddressModal({ isOpen, onClose, modalRef }) {
  const { userAddress, stFitfiBalance } = useContext(ConnectionContext)
  const { user, linkAddress, ethLinkingStatus, setEthLinkingStatus } =
    useContext(UserAuthContext)

  async function linkEthAddress() {
    try {
      await linkAddress(user.id, userAddress)
      onClose()
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setEthLinkingStatus(ETH_LINKING_STATUS.undefined)
    }
  }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal isOpen={isOpen}>
      <ModalContainer ref={modalRef} variant="linkAddress">
        <Text variant="linkAddress">
          Your current stake on {userAddress && shortenAddress(userAddress)} is{' '}
          {stFitfiBalance} stFITFI.{' '}
        </Text>
        <Text variant="linkAddress">
          Please make sure you are linking the correct address.
        </Text>
        <ButtonContainer>
          <Button size="modal" onClick={linkEthAddress}>
            Proceed anyway
          </Button>
          <Button size="modal" onClick={onClose} variant="bordered">
            Cancel
          </Button>
        </ButtonContainer>

        {ethLinkingStatus === 'uniqueError' && (
          <LinkModalErrorContainer>
            <LinkModalErrorText>
              {UniqueAdddresErrorNotification}
            </LinkModalErrorText>
          </LinkModalErrorContainer>
        )}
      </ModalContainer>
    </Modal>
  )
}

export default LinkAddressModal
