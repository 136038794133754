import styled from 'styled-components'

export const AboutSectionContainer = styled.div`
  margin-top: 12px;
  width: 100%;
`

export const AboutBannersContainer = styled.div`
  display: grid;
  grid-template-areas:
    '_ _'
    'a a'
    'b b';

  grid-template-columns: repeat(2, 1fr);
  gap: 12px;

  @media (max-width: 923px) {
    grid-template-columns: 1fr;
    gap: 12px;
    grid-template-areas:
      '_'
      '_'
      'a'
      'b';
  }
`

export const AboutBlockChainContainer = styled.div`
  width: 100%;
  border-radius: 24px;
  position: relative;
  grid-area: a;
  display: flex;
  background: #f3f3f3;
  flex-direction: column;
  align-content: center;
  align-items: center;
  gap: 24px;
  padding: 40px 16px;
  line-height: 160%;
  * {
    z-index: 1;
  }
  button {
    margin: 8px auto 0;
    font-size: 12px;
    letter-spacing: 0.03em;
    padding: 16px 80px;
    font-weight: 400;
  }
  @media (max-width: 768px) {
    margin: 0 20px;
    width: unset;

    button {
      width: 100%;
      max-width: 400px;
    }
  }
`

export const AboutBlockChainImg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  object-fit: cover;
`

export const AboutBlockChainTitle = styled.h1`
  font-size: 60px;
  line-height: 120%;
  font-weight: 500;
  max-width: 792px;
  text-decoration: uppercase;
  text-align: center;
  letter-spacing: -0.03em;
  @media (max-width: 768px) {
    font-size: 32px;
    text-align: unset;
  }
`

export const AboutBlockChainDescription = styled.p`
  max-width: 813px;
  text-align: center;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.03em;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: uppercase;
  @media (max-width: 768px) {
    text-align: unset;
  }
`

export const StepEcosystemContainer = styled.div`
  margin-top: 102px;
  grid-area: b;
  display: flex;
  gap: 60px;
  width: 100%;

  @media (max-width: 1267px) {
    flex-direction: column;
    gap: 0px;
  }
  @media (max-width: 768px) {
    margin: 79px 20px 40px 20px;
    width: unset;
  }
`

export const StepEcoSystemImg = styled.img`
  flex: 1 1 0%;
  border-radius: 24px;
  max-width: 666px;
  max-height: 364px;
  @media (max-width: 768px) {
    max-width: unset;
    width: 100%;
  } ;
`

export const StepEcoSystemTitle = styled.h1`
  font-size: 60px;
  line-height: 100%;
  margin-top: 30px;
  text-decoration: uppercase;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 32px;
  }
`

export const StepEcoSystemDescription = styled.p`
  padding: 24px 30px 0 0;
  line-height: 160%;
  text-decoration: uppercase;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
`

export const AboutSectionDetails = styled.div`
  margin-top: 50px;
  width: 100%;
  @media (max-width: 768px) {
    margin: 0 20px 0 20px;
    width: unset;
  }
`

export const SectionDetail = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  align-items: center;
  @media (max-width: 1267px) {
    padding: 32px 0;
  }
  @media (max-width: 864px) {
    flex-direction: column;
    align-items: unset;
    gap: 16px;
  }
`

export const SectionDetailTitle = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`

export const SectionDetailDescription = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.03em;
  color: rgba(0, 0, 0, 0.8);
  width: 615px;
  text-align: start;
  @media (max-width: 864px) {
    width: 100%;
  }
`

export const AboutBlockChainLink = styled.a`
  text-decoration: none;
  width: 100%;
  max-width: 400px;
`
